import clsx from 'clsx'
import {MutableRefObject, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {ToggleComponent} from '../../../assets/ts/components'
import {KTIcon} from '../../../helpers'
import {useLayout} from '../../core'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {
  const {config} = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <div className='app-sidebar-logo px-6 justify-content-center' id='kt_app_sidebar_logo'>
      <Link to='/welcome'>
        {config.layoutType === 'dark-sidebar' ? (
          <svg
            className='app-sidebar-logo-default text-white'
            width='89'
            height='48'
            viewBox='0 0 69 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_673_43176)'>
              <g clipPath='url(#clip1_673_43176)'>
                <path
                  d='M1.13517 0.409367C1.13517 0.636203 0.96504 0.818734 0.757283 0.818734C0.549526 0.818734 0.379395 0.63443 0.379395 0.409367C0.379395 0.184304 0.54789 0 0.757283 0C0.966676 0 1.13517 0.184304 1.13517 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757382 2.11615C0.976926 2.11615 1.1549 1.92335 1.1549 1.68552C1.1549 1.44768 0.976926 1.25488 0.757382 1.25488C0.537839 1.25488 0.359863 1.44768 0.359863 1.68552C0.359863 1.92335 0.537839 2.11615 0.757382 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.75731 3.40953C0.986792 3.40953 1.17282 3.208 1.17282 2.9594C1.17282 2.71081 0.986792 2.50928 0.75731 2.50928C0.527829 2.50928 0.341797 2.71081 0.341797 2.9594C0.341797 3.208 0.527829 3.40953 0.75731 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.19075 4.23378C1.19075 4.49251 0.997713 4.7034 0.757239 4.7034C0.516764 4.7034 0.32373 4.49429 0.32373 4.23378C0.32373 3.97327 0.516764 3.76416 0.757239 3.76416C0.997713 3.76416 1.19075 3.97327 1.19075 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757655 5.99874C1.00701 5.99874 1.20916 5.77975 1.20916 5.50962C1.20916 5.23949 1.00701 5.02051 0.757655 5.02051C0.508297 5.02051 0.306152 5.23949 0.306152 5.50962C0.306152 5.77975 0.508297 5.99874 0.757655 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757584 7.29261C1.01688 7.29261 1.22708 7.06489 1.22708 6.784C1.22708 6.5031 1.01688 6.27539 0.757584 6.27539C0.498287 6.27539 0.288086 6.5031 0.288086 6.784C0.288086 7.06489 0.498287 7.29261 0.757584 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.245 8.05789C1.245 8.34852 1.02743 8.58599 0.757512 8.58599C0.487592 8.58599 0.27002 8.35029 0.27002 8.05789C0.27002 7.76548 0.487592 7.52979 0.757512 7.52979C1.02743 7.52979 1.245 7.76548 1.245 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.26293 9.33373C1.26293 9.63499 1.03718 9.88132 0.75744 9.88132C0.477705 9.88132 0.251953 9.63676 0.251953 9.33373C0.251953 9.03069 0.477705 8.78613 0.75744 8.78613C1.03718 8.78613 1.26293 9.03069 1.26293 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757368 11.1752C1.04648 11.1752 1.28085 10.9213 1.28085 10.6081C1.28085 10.2949 1.04648 10.041 0.757368 10.041C0.468257 10.041 0.233887 10.2949 0.233887 10.6081C0.233887 10.9213 0.468257 11.1752 0.757368 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757297 12.4691C1.05635 12.4691 1.29877 12.2064 1.29877 11.8825C1.29877 11.5585 1.05635 11.2959 0.757297 11.2959C0.458247 11.2959 0.21582 11.5585 0.21582 11.8825C0.21582 12.2064 0.458247 12.4691 0.757297 12.4691Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757225 13.7644C1.06621 13.7644 1.3167 13.493 1.3167 13.1583C1.3167 12.8236 1.06621 12.5522 0.757225 12.5522C0.448238 12.5522 0.197754 12.8236 0.197754 13.1583C0.197754 13.493 0.448238 13.7644 0.757225 13.7644Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757641 15.0578C1.07657 15.0578 1.33511 14.7777 1.33511 14.4322C1.33511 14.0867 1.07657 13.8066 0.757641 13.8066C0.438716 13.8066 0.180176 14.0867 0.180176 14.4322C0.180176 14.7777 0.438716 15.0578 0.757641 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.35303 15.7066C1.35303 16.0628 1.08638 16.3516 0.75757 16.3516C0.428758 16.3516 0.162109 16.0628 0.162109 15.7066C0.162109 15.3504 0.428758 15.0615 0.75757 15.0615C1.08638 15.0615 1.35303 15.3504 1.35303 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757355 20.2342C1.11603 20.2342 1.4068 19.9192 1.4068 19.5307C1.4068 19.1421 1.11603 18.8271 0.757355 18.8271C0.398676 18.8271 0.10791 19.1421 0.10791 19.5307C0.10791 19.9192 0.398676 20.2342 0.757355 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.42472 20.8051C1.42472 21.2038 1.12536 21.5281 0.757283 21.5281C0.38921 21.5281 0.0898438 21.2038 0.0898438 20.8051C0.0898438 20.4063 0.38921 20.082 0.757283 20.082C1.12536 20.082 1.42472 20.4063 1.42472 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757211 22.8234C1.13577 22.8234 1.44264 22.491 1.44264 22.0809C1.44264 21.6708 1.13577 21.3384 0.757211 21.3384C0.378657 21.3384 0.0717773 21.6708 0.0717773 22.0809C0.0717773 22.491 0.378657 22.8234 0.757211 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.46106 23.3553C1.46106 23.7753 1.14697 24.1173 0.757628 24.1173C0.368288 24.1173 0.0541992 23.7771 0.0541992 23.3553C0.0541992 22.9335 0.368288 22.5933 0.757628 22.5933C1.14697 22.5933 1.46106 22.9335 1.46106 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M1.47898 24.6292C1.47898 25.0616 1.15671 25.4107 0.757556 25.4107C0.358401 25.4107 0.0361328 25.0616 0.0361328 24.6292C0.0361328 24.1968 0.358401 23.8477 0.757556 23.8477C1.15671 23.8477 1.47898 24.1968 1.47898 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757484 26.7065C1.16585 26.7065 1.4969 26.3479 1.4969 25.9055C1.4969 25.4631 1.16585 25.1045 0.757484 25.1045C0.349115 25.1045 0.0180664 25.4631 0.0180664 25.9055C0.0180664 26.3479 0.349115 26.7065 0.757484 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M0.757412 27.9999C1.17572 27.9999 1.51482 27.6325 1.51482 27.1794C1.51482 26.7262 1.17572 26.3589 0.757412 26.3589C0.339105 26.3589 0 26.7262 0 27.1794C0 27.6325 0.339105 27.9999 0.757412 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.46183 0.409367C2.46183 0.636203 2.2917 0.818734 2.08394 0.818734C1.87619 0.818734 1.70605 0.63443 1.70605 0.409367C1.70605 0.184304 1.87619 0 2.08394 0C2.2917 0 2.46183 0.184304 2.46183 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.47993 1.68552C2.47993 1.92298 2.30161 2.11615 2.08241 2.11615C1.8632 2.11615 1.68652 1.92298 1.68652 1.68552C1.68652 1.44805 1.86483 1.25488 2.08241 1.25488C2.29998 1.25488 2.47993 1.44805 2.47993 1.68552Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.49802 2.9594C2.49802 3.20751 2.31153 3.40953 2.08251 3.40953C1.85348 3.40953 1.66699 3.20751 1.66699 2.9594C1.66699 2.7113 1.85348 2.50928 2.08251 2.50928C2.31153 2.50928 2.49802 2.7113 2.49802 2.9594Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.51594 4.23378C2.51594 4.49251 2.32291 4.7034 2.08243 4.7034C1.84196 4.7034 1.64893 4.49429 1.64893 4.23378C1.64893 3.97327 1.84196 3.76416 2.08243 3.76416C2.32291 3.76416 2.51594 3.97327 2.51594 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.53387 5.50962C2.53387 5.77899 2.33265 5.99874 2.08236 5.99874C1.83207 5.99874 1.63086 5.78076 1.63086 5.50962C1.63086 5.23848 1.83207 5.02051 2.08236 5.02051C2.33265 5.02051 2.53387 5.23848 2.53387 5.50962Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.55179 6.784C2.55179 7.064 2.3424 7.29261 2.08229 7.29261C1.82219 7.29261 1.61279 7.06577 1.61279 6.784C1.61279 6.50223 1.82219 6.27539 2.08229 6.27539C2.3424 6.27539 2.55179 6.50223 2.55179 6.784Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.5702 8.05789C2.5702 8.34852 2.35263 8.58599 2.08271 8.58599C1.81279 8.58599 1.59521 8.35029 1.59521 8.05789C1.59521 7.76548 1.81279 7.52979 2.08271 7.52979C2.35263 7.52979 2.5702 7.76548 2.5702 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.58812 9.33373C2.58812 9.63499 2.36237 9.88132 2.08264 9.88132C1.8029 9.88132 1.57715 9.63676 1.57715 9.33373C1.57715 9.03069 1.8029 8.78613 2.08264 8.78613C2.36237 8.78613 2.58812 9.03069 2.58812 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.08256 11.1752C2.37167 11.1752 2.60605 10.9213 2.60605 10.6081C2.60605 10.2949 2.37167 10.041 2.08256 10.041C1.79345 10.041 1.55908 10.2949 1.55908 10.6081C1.55908 10.9213 1.79345 11.1752 2.08256 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.62397 11.8825C2.62397 12.2068 2.38186 12.4691 2.08249 12.4691C1.78313 12.4691 1.54102 12.2068 1.54102 11.8825C1.54102 11.5582 1.78313 11.2959 2.08249 11.2959C2.38186 11.2959 2.62397 11.5582 2.62397 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.64189 13.1583C2.64189 13.4933 2.3916 13.7644 2.08242 13.7644C1.77324 13.7644 1.52295 13.4933 1.52295 13.1583C1.52295 12.8234 1.77324 12.5522 2.08242 12.5522C2.3916 12.5522 2.64189 12.8234 2.64189 13.1583Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.08235 15.0578C2.40127 15.0578 2.65981 14.7777 2.65981 14.4322C2.65981 14.0867 2.40127 13.8066 2.08235 13.8066C1.76342 13.8066 1.50488 14.0867 1.50488 14.4322C1.50488 14.7777 1.76342 15.0578 2.08235 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.08262 18.9409C2.43136 18.9409 2.71407 18.6346 2.71407 18.2568C2.71407 17.879 2.43136 17.5728 2.08262 17.5728C1.73388 17.5728 1.45117 17.879 1.45117 18.2568C1.45117 18.6346 1.73388 18.9409 2.08262 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.73199 19.5307C2.73199 19.9188 2.44081 20.2342 2.08255 20.2342C1.72429 20.2342 1.43311 19.9188 1.43311 19.5307C1.43311 19.1426 1.72429 18.8271 2.08255 18.8271C2.44081 18.8271 2.73199 19.1426 2.73199 19.5307Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.74992 20.8051C2.74992 21.2038 2.45055 21.5281 2.08248 21.5281C1.71441 21.5281 1.41504 21.2038 1.41504 20.8051C1.41504 20.4063 1.71441 20.082 2.08248 20.082C2.45055 20.082 2.74992 20.4063 2.74992 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.76784 22.0809C2.76784 22.4903 2.46029 22.8234 2.08241 22.8234C1.70452 22.8234 1.39697 22.4903 1.39697 22.0809C1.39697 21.6715 1.70452 21.3384 2.08241 21.3384C2.46029 21.3384 2.76784 21.6715 2.76784 22.0809Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.78576 23.3553C2.78576 23.7753 2.47167 24.1173 2.08233 24.1173C1.693 24.1173 1.37891 23.7771 1.37891 23.3553C1.37891 22.9335 1.693 22.5933 2.08233 22.5933C2.47167 22.5933 2.78576 22.9335 2.78576 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.80369 24.6292C2.80369 25.0616 2.48142 25.4107 2.08226 25.4107C1.68311 25.4107 1.36084 25.0616 1.36084 24.6292C1.36084 24.1968 1.68311 23.8477 2.08226 23.8477C2.48142 23.8477 2.80369 24.1968 2.80369 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.8221 25.9055C2.8221 26.3485 2.49165 26.7065 2.08268 26.7065C1.67371 26.7065 1.34326 26.3485 1.34326 25.9055C1.34326 25.4625 1.67371 25.1045 2.08268 25.1045C2.49165 25.1045 2.8221 25.4625 2.8221 25.9055Z'
                  fill='#5039FF'
                />
                <path
                  d='M2.84002 27.1794C2.84002 27.6331 2.50139 27.9999 2.08261 27.9999C1.66382 27.9999 1.3252 27.6331 1.3252 27.1794C1.3252 26.7257 1.66382 26.3589 2.08261 26.3589C2.50139 26.3589 2.84002 26.7257 2.84002 27.1794Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.78703 0.409367C3.78703 0.636203 3.61689 0.818734 3.40914 0.818734C3.20138 0.818734 3.03125 0.63443 3.03125 0.409367C3.03125 0.184304 3.20138 0 3.40914 0C3.61689 0 3.78703 0.184304 3.78703 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40924 2.11615C3.62878 2.11615 3.80676 1.92335 3.80676 1.68552C3.80676 1.44768 3.62878 1.25488 3.40924 1.25488C3.18969 1.25488 3.01172 1.44768 3.01172 1.68552C3.01172 1.92335 3.18969 2.11615 3.40924 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40917 3.40953C3.63865 3.40953 3.82468 3.208 3.82468 2.9594C3.82468 2.71081 3.63865 2.50928 3.40917 2.50928C3.17968 2.50928 2.99365 2.71081 2.99365 2.9594C2.99365 3.208 3.17968 3.40953 3.40917 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.84114 4.23378C3.84114 4.49251 3.6481 4.7034 3.40763 4.7034C3.16715 4.7034 2.97412 4.49429 2.97412 4.23378C2.97412 3.97327 3.16715 3.76416 3.40763 3.76416C3.6481 3.76416 3.84114 3.97327 3.84114 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40902 5.99874C3.65838 5.99874 3.86053 5.77975 3.86053 5.50962C3.86053 5.23949 3.65838 5.02051 3.40902 5.02051C3.15966 5.02051 2.95752 5.23949 2.95752 5.50962C2.95752 5.77975 3.15966 5.99874 3.40902 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40895 7.29261C3.66825 7.29261 3.87845 7.06489 3.87845 6.784C3.87845 6.5031 3.66825 6.27539 3.40895 6.27539C3.14965 6.27539 2.93945 6.5031 2.93945 6.784C2.93945 7.06489 3.14965 7.29261 3.40895 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.89491 8.05789C3.89491 8.34852 3.67733 8.58599 3.40741 8.58599C3.13749 8.58599 2.91992 8.35029 2.91992 8.05789C2.91992 7.76548 3.13749 7.52979 3.40741 7.52979C3.67733 7.52979 3.89491 7.76548 3.89491 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.91283 9.33373C3.91283 9.63499 3.68708 9.88132 3.40734 9.88132C3.12761 9.88132 2.90186 9.63676 2.90186 9.33373C2.90186 9.03069 3.12761 8.78613 3.40734 8.78613C3.68708 8.78613 3.91283 9.03069 3.91283 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40922 11.1752C3.69833 11.1752 3.93271 10.9213 3.93271 10.6081C3.93271 10.2949 3.69833 10.041 3.40922 10.041C3.12011 10.041 2.88574 10.2949 2.88574 10.6081C2.88574 10.9213 3.12011 11.1752 3.40922 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.94916 11.8825C3.94916 12.2068 3.70705 12.4691 3.40769 12.4691C3.10832 12.4691 2.86621 12.2068 2.86621 11.8825C2.86621 11.5582 3.10832 11.2959 3.40769 11.2959C3.70705 11.2959 3.94916 11.5582 3.94916 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.96709 13.1583C3.96709 13.4933 3.7168 13.7644 3.40762 13.7644C3.09843 13.7644 2.84814 13.4933 2.84814 13.1583C2.84814 12.8234 3.09843 12.5522 3.40762 12.5522C3.7168 12.5522 3.96709 12.8234 3.96709 13.1583Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.02281 16.9824C4.02281 17.3493 3.74798 17.647 3.40935 17.647C3.07073 17.647 2.7959 17.3493 2.7959 16.9824C2.7959 16.6156 3.07073 16.3179 3.40935 16.3179C3.74798 16.3179 4.02281 16.6156 4.02281 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40928 18.9409C3.75802 18.9409 4.04073 18.6346 4.04073 18.2568C4.04073 17.879 3.75802 17.5728 3.40928 17.5728C3.06054 17.5728 2.77783 17.879 2.77783 18.2568C2.77783 18.6346 3.06054 18.9409 3.40928 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40921 20.2342C3.76789 20.2342 4.05865 19.9192 4.05865 19.5307C4.05865 19.1421 3.76789 18.8271 3.40921 18.8271C3.05053 18.8271 2.75977 19.1421 2.75977 19.5307C2.75977 19.9192 3.05053 20.2342 3.40921 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.07658 20.8051C4.07658 21.2038 3.77721 21.5281 3.40914 21.5281C3.04107 21.5281 2.7417 21.2038 2.7417 20.8051C2.7417 20.4063 3.04107 20.082 3.40914 20.082C3.77721 20.082 4.07658 20.4063 4.07658 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40907 22.8234C3.78762 22.8234 4.0945 22.491 4.0945 22.0809C4.0945 21.6708 3.78762 21.3384 3.40907 21.3384C3.03051 21.3384 2.72363 21.6708 2.72363 22.0809C2.72363 22.491 3.03051 22.8234 3.40907 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.11242 23.3553C4.11242 23.7753 3.79833 24.1173 3.40899 24.1173C3.01966 24.1173 2.70557 23.7771 2.70557 23.3553C2.70557 22.9335 3.01966 22.5933 3.40899 22.5933C3.79833 22.5933 4.11242 22.9335 4.11242 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.13083 24.6292C4.13083 25.0616 3.80857 25.4107 3.40941 25.4107C3.01026 25.4107 2.68799 25.0616 2.68799 24.6292C2.68799 24.1968 3.01026 23.8477 3.40941 23.8477C3.80857 23.8477 4.13083 24.1968 4.13083 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40934 26.7065C3.81771 26.7065 4.14876 26.3479 4.14876 25.9055C4.14876 25.4631 3.81771 25.1045 3.40934 25.1045C3.00097 25.1045 2.66992 25.4631 2.66992 25.9055C2.66992 26.3479 3.00097 26.7065 3.40934 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M3.40927 27.9999C3.82758 27.9999 4.16668 27.6325 4.16668 27.1794C4.16668 26.7262 3.82758 26.3589 3.40927 26.3589C2.99096 26.3589 2.65186 26.7262 2.65186 27.1794C2.65186 27.6325 2.99096 27.9999 3.40927 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.11369 0.409367C5.11369 0.636203 4.94356 0.818734 4.7358 0.818734C4.52804 0.818734 4.35791 0.63443 4.35791 0.409367C4.35791 0.184304 4.52804 0 4.7358 0C4.94356 0 5.11369 0.184304 5.11369 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73443 2.11615C4.95398 2.11615 5.13195 1.92335 5.13195 1.68552C5.13195 1.44768 4.95398 1.25488 4.73443 1.25488C4.51489 1.25488 4.33691 1.44768 4.33691 1.68552C4.33691 1.92335 4.51489 2.11615 4.73443 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73436 3.40953C4.96384 3.40953 5.14987 3.208 5.14987 2.9594C5.14987 2.71081 4.96384 2.50928 4.73436 2.50928C4.50488 2.50928 4.31885 2.71081 4.31885 2.9594C4.31885 3.208 4.50488 3.40953 4.73436 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.1678 4.23378C5.1678 4.49251 4.97476 4.7034 4.73429 4.7034C4.49382 4.7034 4.30078 4.49429 4.30078 4.23378C4.30078 3.97327 4.49382 3.76416 4.73429 3.76416C4.97476 3.76416 5.1678 3.97327 5.1678 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73422 5.99874C4.98358 5.99874 5.18572 5.77975 5.18572 5.50962C5.18572 5.23949 4.98358 5.02051 4.73422 5.02051C4.48486 5.02051 4.28271 5.23949 4.28271 5.50962C4.28271 5.77975 4.48486 5.99874 4.73422 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73415 7.29261C4.99344 7.29261 5.20364 7.06489 5.20364 6.784C5.20364 6.5031 4.99344 6.27539 4.73415 6.27539C4.47485 6.27539 4.26465 6.5031 4.26465 6.784C4.26465 7.06489 4.47485 7.29261 4.73415 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.22157 8.05789C5.22157 8.34852 5.00399 8.58599 4.73407 8.58599C4.46415 8.58599 4.24658 8.35029 4.24658 8.05789C4.24658 7.76548 4.46415 7.52979 4.73407 7.52979C5.00399 7.52979 5.22157 7.76548 5.22157 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.23949 9.33373C5.23949 9.63499 5.01374 9.88132 4.734 9.88132C4.45427 9.88132 4.22852 9.63676 4.22852 9.33373C4.22852 9.03069 4.45427 8.78613 4.734 8.78613C5.01374 8.78613 5.23949 9.03069 5.23949 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73442 11.1752C5.02353 11.1752 5.2579 10.9213 5.2579 10.6081C5.2579 10.2949 5.02353 10.041 4.73442 10.041C4.44531 10.041 4.21094 10.2949 4.21094 10.6081C4.21094 10.9213 4.44531 11.1752 4.73442 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.27582 11.8825C5.27582 12.2068 5.03371 12.4691 4.73435 12.4691C4.43498 12.4691 4.19287 12.2068 4.19287 11.8825C4.19287 11.5582 4.43498 11.2959 4.73435 11.2959C5.03371 11.2959 5.27582 11.5582 5.27582 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.32959 15.7066C5.32959 16.0628 5.06294 16.3516 4.73413 16.3516C4.40532 16.3516 4.13867 16.0628 4.13867 15.7066C4.13867 15.3504 4.40532 15.0615 4.73413 15.0615C5.06294 15.0615 5.32959 15.3504 5.32959 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.34752 16.9824C5.34752 17.3493 5.07269 17.647 4.73406 17.647C4.39543 17.647 4.12061 17.3493 4.12061 16.9824C4.12061 16.6156 4.39543 16.3179 4.73406 16.3179C5.07269 16.3179 5.34752 16.6156 5.34752 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73448 18.9409C5.08322 18.9409 5.36593 18.6346 5.36593 18.2568C5.36593 17.879 5.08322 17.5728 4.73448 17.5728C4.38574 17.5728 4.10303 17.879 4.10303 18.2568C4.10303 18.6346 4.38574 18.9409 4.73448 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73441 20.2342C5.09308 20.2342 5.38385 19.9192 5.38385 19.5307C5.38385 19.1421 5.09308 18.8271 4.73441 18.8271C4.37573 18.8271 4.08496 19.1421 4.08496 19.5307C4.08496 19.9192 4.37573 20.2342 4.73441 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.40177 20.8051C5.40177 21.2038 5.10241 21.5281 4.73433 21.5281C4.36626 21.5281 4.06689 21.2038 4.06689 20.8051C4.06689 20.4063 4.36626 20.082 4.73433 20.082C5.10241 20.082 5.40177 20.4063 5.40177 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73426 22.8234C5.11282 22.8234 5.4197 22.491 5.4197 22.0809C5.4197 21.6708 5.11282 21.3384 4.73426 21.3384C4.35571 21.3384 4.04883 21.6708 4.04883 22.0809C4.04883 22.491 4.35571 22.8234 4.73426 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.43762 23.3553C5.43762 23.7753 5.12353 24.1173 4.73419 24.1173C4.34485 24.1173 4.03076 23.7771 4.03076 23.3553C4.03076 22.9335 4.34485 22.5933 4.73419 22.5933C5.12353 22.5933 5.43762 22.9335 5.43762 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M5.45554 24.6292C5.45554 25.0616 5.13327 25.4107 4.73412 25.4107C4.33496 25.4107 4.0127 25.0616 4.0127 24.6292C4.0127 24.1968 4.33496 23.8477 4.73412 23.8477C5.13327 23.8477 5.45554 24.1968 5.45554 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73405 26.7065C5.14242 26.7065 5.47346 26.3479 5.47346 25.9055C5.47346 25.4631 5.14242 25.1045 4.73405 25.1045C4.32568 25.1045 3.99463 25.4631 3.99463 25.9055C3.99463 26.3479 4.32568 26.7065 4.73405 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M4.73446 27.9999C5.15277 27.9999 5.49188 27.6325 5.49188 27.1794C5.49188 26.7262 5.15277 26.3589 4.73446 26.3589C4.31616 26.3589 3.97705 26.7262 3.97705 27.1794C3.97705 27.6325 4.31616 27.9999 4.73446 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.43888 0.409367C6.43888 0.636203 6.26875 0.818734 6.06099 0.818734C5.85324 0.818734 5.68311 0.63443 5.68311 0.409367C5.68311 0.184304 5.85324 0 6.06099 0C6.26875 0 6.43888 0.184304 6.43888 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.45698 1.68552C6.45698 1.92298 6.27867 2.11615 6.06109 2.11615C5.84352 2.11615 5.66357 1.92298 5.66357 1.68552C5.66357 1.44805 5.84189 1.25488 6.06109 1.25488C6.2803 1.25488 6.45698 1.44805 6.45698 1.68552Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.47458 2.9594C6.47458 3.20751 6.28809 3.40953 6.05907 3.40953C5.83005 3.40953 5.64355 3.20751 5.64355 2.9594C5.64355 2.7113 5.83005 2.50928 6.05907 2.50928C6.28809 2.50928 6.47458 2.7113 6.47458 2.9594Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.49299 4.23378C6.49299 4.49251 6.29996 4.7034 6.05949 4.7034C5.81901 4.7034 5.62598 4.49429 5.62598 4.23378C5.62598 3.97327 5.81901 3.76416 6.05949 3.76416C6.29996 3.76416 6.49299 3.97327 6.49299 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.51092 5.50962C6.51092 5.77899 6.3097 5.99874 6.05941 5.99874C5.80912 5.99874 5.60791 5.78076 5.60791 5.50962C5.60791 5.23848 5.80912 5.02051 6.05941 5.02051C6.3097 5.02051 6.51092 5.23848 6.51092 5.50962Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.52884 6.784C6.52884 7.064 6.31945 7.29261 6.05934 7.29261C5.79924 7.29261 5.58984 7.06577 5.58984 6.784C5.58984 6.50223 5.79924 6.27539 6.05934 6.27539C6.31945 6.27539 6.52884 6.50223 6.52884 6.784Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.54676 8.05789C6.54676 8.34852 6.32919 8.58599 6.05927 8.58599C5.78935 8.58599 5.57178 8.35029 5.57178 8.05789C5.57178 7.76548 5.78935 7.52979 6.05927 7.52979C6.32919 7.52979 6.54676 7.76548 6.54676 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.56468 9.33373C6.56468 9.63499 6.33893 9.88132 6.0592 9.88132C5.77946 9.88132 5.55371 9.63676 5.55371 9.33373C5.55371 9.03069 5.77946 8.78613 6.0592 8.78613C6.33893 8.78613 6.56468 9.03069 6.56468 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.06108 11.1752C6.35019 11.1752 6.58456 10.9213 6.58456 10.6081C6.58456 10.2949 6.35019 10.041 6.06108 10.041C5.77197 10.041 5.5376 10.2949 5.5376 10.6081C5.5376 10.9213 5.77197 11.1752 6.06108 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.06086 15.0578C6.37979 15.0578 6.63833 14.7777 6.63833 14.4322C6.63833 14.0867 6.37979 13.8066 6.06086 13.8066C5.74194 13.8066 5.4834 14.0867 5.4834 14.4322C5.4834 14.7777 5.74194 15.0578 6.06086 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.65479 15.7066C6.65479 16.0628 6.38814 16.3516 6.05933 16.3516C5.73052 16.3516 5.46387 16.0628 5.46387 15.7066C5.46387 15.3504 5.73052 15.0615 6.05933 15.0615C6.38814 15.0615 6.65479 15.3504 6.65479 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.67271 16.9824C6.67271 17.3493 6.39788 17.647 6.05926 17.647C5.72063 17.647 5.4458 17.3493 5.4458 16.9824C5.4458 16.6156 5.72063 16.3179 6.05926 16.3179C6.39788 16.3179 6.67271 16.6156 6.67271 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.06114 18.9409C6.40988 18.9409 6.69259 18.6346 6.69259 18.2568C6.69259 17.879 6.40988 17.5728 6.06114 17.5728C5.7124 17.5728 5.42969 17.879 5.42969 18.2568C5.42969 18.6346 5.7124 18.9409 6.06114 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.70856 19.5307C6.70856 19.9188 6.41737 20.2342 6.05911 20.2342C5.70085 20.2342 5.40967 19.9188 5.40967 19.5307C5.40967 19.1426 5.70085 18.8271 6.05911 18.8271C6.41737 18.8271 6.70856 19.1426 6.70856 19.5307Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.72697 20.8051C6.72697 21.2038 6.4276 21.5281 6.05953 21.5281C5.69146 21.5281 5.39209 21.2038 5.39209 20.8051C5.39209 20.4063 5.69146 20.082 6.05953 20.082C6.4276 20.082 6.72697 20.4063 6.72697 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.74489 22.0809C6.74489 22.4903 6.43735 22.8234 6.05946 22.8234C5.68157 22.8234 5.37402 22.4903 5.37402 22.0809C5.37402 21.6715 5.68157 21.3384 6.05946 21.3384C6.43735 21.3384 6.74489 21.6715 6.74489 22.0809Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.76281 23.3553C6.76281 23.7753 6.44872 24.1173 6.05939 24.1173C5.67005 24.1173 5.35596 23.7771 5.35596 23.3553C5.35596 22.9335 5.67005 22.5933 6.05939 22.5933C6.44872 22.5933 6.76281 22.9335 6.76281 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.78074 24.6292C6.78074 25.0616 6.45847 25.4107 6.05931 25.4107C5.66016 25.4107 5.33789 25.0616 5.33789 24.6292C5.33789 24.1968 5.66016 23.8477 6.05931 23.8477C6.45847 23.8477 6.78074 24.1968 6.78074 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.79866 25.9055C6.79866 26.3485 6.46821 26.7065 6.05924 26.7065C5.65027 26.7065 5.31982 26.3485 5.31982 25.9055C5.31982 25.4625 5.65027 25.1045 6.05924 25.1045C6.46821 25.1045 6.79866 25.4625 6.79866 25.9055Z'
                  fill='#5039FF'
                />
                <path
                  d='M6.81854 27.1794C6.81854 27.6331 6.47991 27.9999 6.06112 27.9999C5.64234 27.9999 5.30371 27.6331 5.30371 27.1794C5.30371 26.7257 5.64234 26.3589 6.06112 26.3589C6.47991 26.3589 6.81854 26.7257 6.81854 27.1794Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.76408 0.409367C7.76408 0.636203 7.59395 0.818734 7.38619 0.818734C7.17843 0.818734 7.0083 0.63443 7.0083 0.409367C7.0083 0.184304 7.17843 0 7.38619 0C7.59395 0 7.76408 0.184304 7.76408 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.3858 2.11615C7.60534 2.11615 7.78332 1.92335 7.78332 1.68552C7.78332 1.44768 7.60534 1.25488 7.3858 1.25488C7.16626 1.25488 6.98828 1.44768 6.98828 1.68552C6.98828 1.92335 7.16626 2.11615 7.3858 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38622 3.40953C7.6157 3.40953 7.80173 3.208 7.80173 2.9594C7.80173 2.71081 7.6157 2.50928 7.38622 2.50928C7.15673 2.50928 6.9707 2.71081 6.9707 2.9594C6.9707 3.208 7.15673 3.40953 7.38622 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.8177 4.23378C7.8177 4.49251 7.62467 4.7034 7.38419 4.7034C7.14372 4.7034 6.95068 4.49429 6.95068 4.23378C6.95068 3.97327 7.14372 3.76416 7.38419 3.76416C7.62467 3.76416 7.8177 3.97327 7.8177 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38607 5.99874C7.63543 5.99874 7.83758 5.77975 7.83758 5.50962C7.83758 5.23949 7.63543 5.02051 7.38607 5.02051C7.13672 5.02051 6.93457 5.23949 6.93457 5.50962C6.93457 5.77975 7.13672 5.99874 7.38607 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.386 7.29261C7.6453 7.29261 7.8555 7.06489 7.8555 6.784C7.8555 6.5031 7.6453 6.27539 7.386 6.27539C7.12671 6.27539 6.9165 6.5031 6.9165 6.784C6.9165 7.06489 7.12671 7.29261 7.386 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.87342 8.05789C7.87342 8.34852 7.65585 8.58599 7.38593 8.58599C7.11601 8.58599 6.89844 8.35029 6.89844 8.05789C6.89844 7.76548 7.11601 7.52979 7.38593 7.52979C7.65585 7.52979 7.87342 7.76548 7.87342 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.89135 9.33373C7.89135 9.63499 7.66559 9.88132 7.38586 9.88132C7.10612 9.88132 6.88037 9.63676 6.88037 9.33373C6.88037 9.03069 7.10612 8.78613 7.38586 8.78613C7.66559 8.78613 7.89135 9.03069 7.89135 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38579 11.1752C7.6749 11.1752 7.90927 10.9213 7.90927 10.6081C7.90927 10.2949 7.6749 10.041 7.38579 10.041C7.09668 10.041 6.8623 10.2949 6.8623 10.6081C6.8623 10.9213 7.09668 11.1752 7.38579 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38606 15.0578C7.70499 15.0578 7.96353 14.7777 7.96353 14.4322C7.96353 14.0867 7.70499 13.8066 7.38606 13.8066C7.06713 13.8066 6.80859 14.0867 6.80859 14.4322C6.80859 14.7777 7.06713 15.0578 7.38606 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.98145 15.7066C7.98145 16.0628 7.7148 16.3516 7.38599 16.3516C7.05718 16.3516 6.79053 16.0628 6.79053 15.7066C6.79053 15.3504 7.05718 15.0615 7.38599 15.0615C7.7148 15.0615 7.98145 15.3504 7.98145 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.99937 16.9824C7.99937 17.3493 7.72454 17.647 7.38592 17.647C7.04729 17.647 6.77246 17.3493 6.77246 16.9824C6.77246 16.6156 7.04729 16.3179 7.38592 16.3179C7.72454 16.3179 7.99937 16.6156 7.99937 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38584 18.9409C7.73458 18.9409 8.01729 18.6346 8.01729 18.2568C8.01729 17.879 7.73458 17.5728 7.38584 17.5728C7.0371 17.5728 6.75439 17.879 6.75439 18.2568C6.75439 18.6346 7.0371 18.9409 7.38584 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38577 20.2342C7.74445 20.2342 8.03522 19.9192 8.03522 19.5307C8.03522 19.1421 7.74445 18.8271 7.38577 18.8271C7.02709 18.8271 6.73633 19.1421 6.73633 19.5307C6.73633 19.9192 7.02709 20.2342 7.38577 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.05363 20.8051C8.05363 21.2038 7.75426 21.5281 7.38619 21.5281C7.01812 21.5281 6.71875 21.2038 6.71875 20.8051C6.71875 20.4063 7.01812 20.082 7.38619 20.082C7.75426 20.082 8.05363 20.4063 8.05363 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38612 22.8234C7.76467 22.8234 8.07155 22.491 8.07155 22.0809C8.07155 21.6708 7.76467 21.3384 7.38612 21.3384C7.00756 21.3384 6.70068 21.6708 6.70068 22.0809C6.70068 22.491 7.00756 22.8234 7.38612 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.08947 23.3553C8.08947 23.7753 7.77539 24.1173 7.38605 24.1173C6.99671 24.1173 6.68262 23.7771 6.68262 23.3553C6.68262 22.9335 6.99671 22.5933 7.38605 22.5933C7.77539 22.5933 8.08947 22.9335 8.08947 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.1074 24.6292C8.1074 25.0616 7.78513 25.4107 7.38597 25.4107C6.98682 25.4107 6.66455 25.0616 6.66455 24.6292C6.66455 24.1968 6.98682 23.8477 7.38597 23.8477C7.78513 23.8477 8.1074 24.1968 8.1074 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.3859 26.7065C7.79427 26.7065 8.12532 26.3479 8.12532 25.9055C8.12532 25.4631 7.79427 25.1045 7.3859 25.1045C6.97753 25.1045 6.64648 25.4631 6.64648 25.9055C6.64648 26.3479 6.97753 26.7065 7.3859 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M7.38583 27.9999C7.80414 27.9999 8.14324 27.6325 8.14324 27.1794C8.14324 26.7262 7.80414 26.3589 7.38583 26.3589C6.96752 26.3589 6.62842 26.7262 6.62842 27.1794C6.62842 27.6325 6.96752 27.9999 7.38583 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.09074 0.409367C9.09074 0.636203 8.92061 0.818734 8.71285 0.818734C8.50509 0.818734 8.33496 0.63443 8.33496 0.409367C8.33496 0.184304 8.50509 0 8.71285 0C8.92061 0 9.09074 0.184304 9.09074 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.10834 1.68552C9.10834 1.92298 8.93003 2.11615 8.71082 2.11615C8.49162 2.11615 8.31494 1.92298 8.31494 1.68552C8.31494 1.44805 8.49325 1.25488 8.71082 1.25488C8.9284 1.25488 9.10834 1.44805 9.10834 1.68552Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.12644 2.9594C9.12644 3.20751 8.93995 3.40953 8.71092 3.40953C8.4819 3.40953 8.29541 3.20751 8.29541 2.9594C8.29541 2.7113 8.4819 2.50928 8.71092 2.50928C8.93995 2.50928 9.12644 2.7113 9.12644 2.9594Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.14436 4.23378C9.14436 4.49251 8.95133 4.7034 8.71085 4.7034C8.47038 4.7034 8.27734 4.49429 8.27734 4.23378C8.27734 3.97327 8.47038 3.76416 8.71085 3.76416C8.95133 3.76416 9.14436 3.97327 9.14436 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.16277 5.50962C9.16277 5.77899 8.96156 5.99874 8.71127 5.99874C8.46098 5.99874 8.25977 5.78076 8.25977 5.50962C8.25977 5.23848 8.46098 5.02051 8.71127 5.02051C8.96156 5.02051 9.16277 5.23848 9.16277 5.50962Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.7112 7.29261C8.97049 7.29261 9.18069 7.06489 9.18069 6.784C9.18069 6.5031 8.97049 6.27539 8.7112 6.27539C8.4519 6.27539 8.2417 6.5031 8.2417 6.784C8.2417 7.06489 8.4519 7.29261 8.7112 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.19862 8.05789C9.19862 8.34852 8.98105 8.58599 8.71113 8.58599C8.4412 8.58599 8.22363 8.35029 8.22363 8.05789C8.22363 7.76548 8.4412 7.52979 8.71113 7.52979C8.98105 7.52979 9.19862 7.76548 9.19862 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.21654 9.33373C9.21654 9.63499 8.99079 9.88132 8.71105 9.88132C8.43132 9.88132 8.20557 9.63676 8.20557 9.33373C8.20557 9.03069 8.43132 8.78613 8.71105 8.78613C8.99079 8.78613 9.21654 9.03069 9.21654 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.71098 11.1752C9.00009 11.1752 9.23446 10.9213 9.23446 10.6081C9.23446 10.2949 9.00009 10.041 8.71098 10.041C8.42187 10.041 8.1875 10.2949 8.1875 10.6081C8.1875 10.9213 8.42187 11.1752 8.71098 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.25239 11.8825C9.25239 12.2068 9.01028 12.4691 8.71091 12.4691C8.41154 12.4691 8.16943 12.2068 8.16943 11.8825C8.16943 11.5582 8.41154 11.2959 8.71091 11.2959C9.01028 11.2959 9.25239 11.5582 9.25239 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.30664 15.7066C9.30664 16.0628 9.04 16.3516 8.71118 16.3516C8.38237 16.3516 8.11572 16.0628 8.11572 15.7066C8.11572 15.3504 8.38237 15.0615 8.71118 15.0615C9.04 15.0615 9.30664 15.3504 9.30664 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.32457 16.9824C9.32457 17.3493 9.04974 17.647 8.71111 17.647C8.37248 17.647 8.09766 17.3493 8.09766 16.9824C8.09766 16.6156 8.37248 16.3179 8.71111 16.3179C9.04974 16.3179 9.32457 16.6156 9.32457 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.71104 18.9409C9.05978 18.9409 9.34249 18.6346 9.34249 18.2568C9.34249 17.879 9.05978 17.5728 8.71104 17.5728C8.3623 17.5728 8.07959 17.879 8.07959 18.2568C8.07959 18.6346 8.3623 18.9409 8.71104 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.36041 19.5307C9.36041 19.9188 9.06923 20.2342 8.71097 20.2342C8.35271 20.2342 8.06152 19.9188 8.06152 19.5307C8.06152 19.1426 8.35271 18.8271 8.71097 18.8271C9.06923 18.8271 9.36041 19.1426 9.36041 19.5307Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.37834 20.8051C9.37834 21.2038 9.07897 21.5281 8.7109 21.5281C8.34282 21.5281 8.04346 21.2038 8.04346 20.8051C8.04346 20.4063 8.34282 20.082 8.7109 20.082C9.07897 20.082 9.37834 20.4063 9.37834 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.39626 22.0809C9.39626 22.4903 9.08871 22.8234 8.71082 22.8234C8.33294 22.8234 8.02539 22.4903 8.02539 22.0809C8.02539 21.6715 8.33294 21.3384 8.71082 21.3384C9.08871 21.3384 9.39626 21.6715 9.39626 22.0809Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.41467 23.3553C9.41467 23.7753 9.10058 24.1173 8.71124 24.1173C8.3219 24.1173 8.00781 23.7771 8.00781 23.3553C8.00781 22.9335 8.3219 22.5933 8.71124 22.5933C9.10058 22.5933 9.41467 22.9335 9.41467 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.43259 24.6292C9.43259 25.0616 9.11032 25.4107 8.71117 25.4107C8.31201 25.4107 7.98975 25.0616 7.98975 24.6292C7.98975 24.1968 8.31201 23.8477 8.71117 23.8477C9.11032 23.8477 9.43259 24.1968 9.43259 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M9.45052 25.9055C9.45052 26.3485 9.12007 26.7065 8.7111 26.7065C8.30213 26.7065 7.97168 26.3485 7.97168 25.9055C7.97168 25.4625 8.30213 25.1045 8.7111 25.1045C9.12007 25.1045 9.45052 25.4625 9.45052 25.9055Z'
                  fill='#5039FF'
                />
                <path
                  d='M8.71103 27.9999C9.12933 27.9999 9.46844 27.6325 9.46844 27.1794C9.46844 26.7262 9.12933 26.3589 8.71103 26.3589C8.29272 26.3589 7.95361 26.7262 7.95361 27.1794C7.95361 27.6325 8.29272 27.9999 8.71103 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.4154 0.409367C10.4154 0.636203 10.2453 0.818734 10.0376 0.818734C9.8298 0.818734 9.65967 0.63443 9.65967 0.409367C9.65967 0.184304 9.8298 0 10.0376 0C10.2453 0 10.4154 0.184304 10.4154 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0377 2.11615C10.2572 2.11615 10.4352 1.92335 10.4352 1.68552C10.4352 1.44768 10.2572 1.25488 10.0377 1.25488C9.81811 1.25488 9.64014 1.44768 9.64014 1.68552C9.64014 1.92335 9.81811 2.11615 10.0377 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0376 3.40953C10.2671 3.40953 10.4531 3.208 10.4531 2.9594C10.4531 2.71081 10.2671 2.50928 10.0376 2.50928C9.8081 2.50928 9.62207 2.71081 9.62207 2.9594C9.62207 3.208 9.8081 3.40953 10.0376 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.4696 4.23378C10.4696 4.49251 10.2765 4.7034 10.036 4.7034C9.79557 4.7034 9.60254 4.49429 9.60254 4.23378C9.60254 3.97327 9.79557 3.76416 10.036 3.76416C10.2765 3.76416 10.4696 3.97327 10.4696 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0379 5.99874C10.2873 5.99874 10.4894 5.77975 10.4894 5.50962C10.4894 5.23949 10.2873 5.02051 10.0379 5.02051C9.78857 5.02051 9.58643 5.23949 9.58643 5.50962C9.58643 5.77975 9.78857 5.99874 10.0379 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0379 7.29261C10.2972 7.29261 10.5074 7.06489 10.5074 6.784C10.5074 6.5031 10.2972 6.27539 10.0379 6.27539C9.77856 6.27539 9.56836 6.5031 9.56836 6.784C9.56836 7.06489 9.77856 7.29261 10.0379 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.5238 8.05789C10.5238 8.34852 10.3062 8.58599 10.0363 8.58599C9.7664 8.58599 9.54883 8.35029 9.54883 8.05789C9.54883 7.76548 9.7664 7.52979 10.0363 7.52979C10.3062 7.52979 10.5238 7.76548 10.5238 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.5417 9.33373C10.5417 9.63499 10.316 9.88132 10.0362 9.88132C9.75651 9.88132 9.53076 9.63676 9.53076 9.33373C9.53076 9.03069 9.75651 8.78613 10.0362 8.78613C10.316 8.78613 10.5417 9.03069 10.5417 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0376 11.1752C10.3268 11.1752 10.5611 10.9213 10.5611 10.6081C10.5611 10.2949 10.3268 10.041 10.0376 10.041C9.74853 10.041 9.51416 10.2949 9.51416 10.6081C9.51416 10.9213 9.74853 11.1752 10.0376 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.5776 11.8825C10.5776 12.2068 10.3355 12.4691 10.0361 12.4691C9.73674 12.4691 9.49463 12.2068 9.49463 11.8825C9.49463 11.5582 9.73674 11.2959 10.0361 11.2959C10.3355 11.2959 10.5776 11.5582 10.5776 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.5955 13.1583C10.5955 13.4933 10.3452 13.7644 10.036 13.7644C9.72685 13.7644 9.47656 13.4933 9.47656 13.1583C9.47656 12.8234 9.72685 12.5522 10.036 12.5522C10.3452 12.5522 10.5955 12.8234 10.5955 13.1583Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.6512 16.9824C10.6512 17.3493 10.3764 17.647 10.0378 17.647C9.69914 17.647 9.42432 17.3493 9.42432 16.9824C9.42432 16.6156 9.69914 16.3179 10.0378 16.3179C10.3764 16.3179 10.6512 16.6156 10.6512 16.9824Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0377 18.9409C10.3864 18.9409 10.6692 18.6346 10.6692 18.2568C10.6692 17.879 10.3864 17.5728 10.0377 17.5728C9.68896 17.5728 9.40625 17.879 9.40625 18.2568C9.40625 18.6346 9.68896 18.9409 10.0377 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0376 20.2342C10.3963 20.2342 10.6871 19.9192 10.6871 19.5307C10.6871 19.1421 10.3963 18.8271 10.0376 18.8271C9.67895 18.8271 9.38818 19.1421 9.38818 19.5307C9.38818 19.9192 9.67895 20.2342 10.0376 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.705 20.8051C10.705 21.2038 10.4056 21.5281 10.0376 21.5281C9.66948 21.5281 9.37012 21.2038 9.37012 20.8051C9.37012 20.4063 9.66948 20.082 10.0376 20.082C10.4056 20.082 10.705 20.4063 10.705 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.038 22.8234C10.4165 22.8234 10.7234 22.491 10.7234 22.0809C10.7234 21.6708 10.4165 21.3384 10.038 21.3384C9.65942 21.3384 9.35254 21.6708 9.35254 22.0809C9.35254 22.491 9.65942 22.8234 10.038 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.7413 23.3553C10.7413 23.7753 10.4272 24.1173 10.0379 24.1173C9.64856 24.1173 9.33447 23.7771 9.33447 23.3553C9.33447 22.9335 9.64856 22.5933 10.0379 22.5933C10.4272 22.5933 10.7413 22.9335 10.7413 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.7593 24.6292C10.7593 25.0616 10.437 25.4107 10.0378 25.4107C9.63867 25.4107 9.31641 25.0616 9.31641 24.6292C9.31641 24.1968 9.63867 23.8477 10.0378 23.8477C10.437 23.8477 10.7593 24.1968 10.7593 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0378 26.7065C10.4461 26.7065 10.7772 26.3479 10.7772 25.9055C10.7772 25.4631 10.4461 25.1045 10.0378 25.1045C9.62939 25.1045 9.29834 25.4631 9.29834 25.9055C9.29834 26.3479 9.62939 26.7065 10.0378 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M10.0377 27.9999C10.456 27.9999 10.7951 27.6325 10.7951 27.1794C10.7951 26.7262 10.456 26.3589 10.0377 26.3589C9.61938 26.3589 9.28027 26.7262 9.28027 27.1794C9.28027 27.6325 9.61938 27.9999 10.0377 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.7421 0.409367C11.7421 0.636203 11.572 0.818734 11.3642 0.818734C11.1565 0.818734 10.9863 0.63443 10.9863 0.409367C10.9863 0.184304 11.1565 0 11.3642 0C11.572 0 11.7421 0.184304 11.7421 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3629 2.11615C11.5824 2.11615 11.7604 1.92335 11.7604 1.68552C11.7604 1.44768 11.5824 1.25488 11.3629 1.25488C11.1433 1.25488 10.9653 1.44768 10.9653 1.68552C10.9653 1.92335 11.1433 2.11615 11.3629 2.11615Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3628 3.40953C11.5923 3.40953 11.7783 3.208 11.7783 2.9594C11.7783 2.71081 11.5923 2.50928 11.3628 2.50928C11.1333 2.50928 10.9473 2.71081 10.9473 2.9594C10.9473 3.208 11.1333 3.40953 11.3628 3.40953Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.7962 4.23378C11.7962 4.49251 11.6032 4.7034 11.3627 4.7034C11.1222 4.7034 10.9292 4.49429 10.9292 4.23378C10.9292 3.97327 11.1222 3.76416 11.3627 3.76416C11.6032 3.76416 11.7962 3.97327 11.7962 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3626 5.99874C11.612 5.99874 11.8141 5.77975 11.8141 5.50962C11.8141 5.23949 11.612 5.02051 11.3626 5.02051C11.1133 5.02051 10.9111 5.23949 10.9111 5.50962C10.9111 5.77975 11.1133 5.99874 11.3626 5.99874Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3631 7.29261C11.6223 7.29261 11.8326 7.06489 11.8326 6.784C11.8326 6.5031 11.6223 6.27539 11.3631 6.27539C11.1038 6.27539 10.8936 6.5031 10.8936 6.784C10.8936 7.06489 11.1038 7.29261 11.3631 7.29261Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.8505 8.05789C11.8505 8.34852 11.6329 8.58599 11.363 8.58599C11.0931 8.58599 10.8755 8.35029 10.8755 8.05789C10.8755 7.76548 11.0931 7.52979 11.363 7.52979C11.6329 7.52979 11.8505 7.76548 11.8505 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.8684 9.33373C11.8684 9.63499 11.6426 9.88132 11.3629 9.88132C11.0832 9.88132 10.8574 9.63676 10.8574 9.33373C10.8574 9.03069 11.0832 8.78613 11.3629 8.78613C11.6426 8.78613 11.8684 9.03069 11.8684 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3628 11.1752C11.6519 11.1752 11.8863 10.9213 11.8863 10.6081C11.8863 10.2949 11.6519 10.041 11.3628 10.041C11.0737 10.041 10.8394 10.2949 10.8394 10.6081C10.8394 10.9213 11.0737 11.1752 11.3628 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.9042 11.8825C11.9042 12.2068 11.6621 12.4691 11.3628 12.4691C11.0634 12.4691 10.8213 12.2068 10.8213 11.8825C10.8213 11.5582 11.0634 11.2959 11.3628 11.2959C11.6621 11.2959 11.9042 11.5582 11.9042 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.9222 13.1583C11.9222 13.4933 11.6719 13.7644 11.3627 13.7644C11.0535 13.7644 10.8032 13.4933 10.8032 13.1583C10.8032 12.8234 11.0535 12.5522 11.3627 12.5522C11.6719 12.5522 11.9222 12.8234 11.9222 13.1583Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3626 15.0578C11.6815 15.0578 11.9401 14.7777 11.9401 14.4322C11.9401 14.0867 11.6815 13.8066 11.3626 13.8066C11.0437 13.8066 10.7852 14.0867 10.7852 14.4322C10.7852 14.7777 11.0437 15.0578 11.3626 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3629 18.9409C11.7116 18.9409 11.9943 18.6346 11.9943 18.2568C11.9943 17.879 11.7116 17.5728 11.3629 17.5728C11.0142 17.5728 10.7314 17.879 10.7314 18.2568C10.7314 18.6346 11.0142 18.9409 11.3629 18.9409Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3628 20.2342C11.7215 20.2342 12.0123 19.9192 12.0123 19.5307C12.0123 19.1421 11.7215 18.8271 11.3628 18.8271C11.0041 18.8271 10.7134 19.1421 10.7134 19.5307C10.7134 19.9192 11.0041 20.2342 11.3628 20.2342Z'
                  fill='#5039FF'
                />
                <path
                  d='M12.0302 20.8051C12.0302 21.2038 11.7308 21.5281 11.3628 21.5281C10.9947 21.5281 10.6953 21.2038 10.6953 20.8051C10.6953 20.4063 10.9947 20.082 11.3628 20.082C11.7308 20.082 12.0302 20.4063 12.0302 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3627 22.8234C11.7412 22.8234 12.0481 22.491 12.0481 22.0809C12.0481 21.6708 11.7412 21.3384 11.3627 21.3384C10.9841 21.3384 10.6772 21.6708 10.6772 22.0809C10.6772 22.491 10.9841 22.8234 11.3627 22.8234Z'
                  fill='#5039FF'
                />
                <path
                  d='M12.066 23.3553C12.066 23.7753 11.7519 24.1173 11.3626 24.1173C10.9733 24.1173 10.6592 23.7771 10.6592 23.3553C10.6592 22.9335 10.9733 22.5933 11.3626 22.5933C11.7519 22.5933 12.066 22.9335 12.066 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M12.0844 24.6292C12.0844 25.0616 11.7622 25.4107 11.363 25.4107C10.9639 25.4107 10.6416 25.0616 10.6416 24.6292C10.6416 24.1968 10.9639 23.8477 11.363 23.8477C11.7622 23.8477 12.0844 24.1968 12.0844 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.363 26.7065C11.7713 26.7065 12.1024 26.3479 12.1024 25.9055C12.1024 25.4631 11.7713 25.1045 11.363 25.1045C10.9546 25.1045 10.6235 25.4631 10.6235 25.9055C10.6235 26.3479 10.9546 26.7065 11.363 26.7065Z'
                  fill='#5039FF'
                />
                <path
                  d='M11.3629 27.9999C11.7812 27.9999 12.1203 27.6325 12.1203 27.1794C12.1203 26.7262 11.7812 26.3589 11.3629 26.3589C10.9446 26.3589 10.6055 26.7262 10.6055 27.1794C10.6055 27.6325 10.9446 27.9999 11.3629 27.9999Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.0673 0.409367C13.0673 0.636203 12.8972 0.818734 12.6894 0.818734C12.4817 0.818734 12.3115 0.63443 12.3115 0.409367C12.3115 0.184304 12.4817 0 12.6894 0C12.8972 0 13.0673 0.184304 13.0673 0.409367Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.0854 1.68552C13.0854 1.92298 12.9071 2.11615 12.6895 2.11615C12.4719 2.11615 12.292 1.92298 12.292 1.68552C12.292 1.44805 12.4703 1.25488 12.6895 1.25488C12.9087 1.25488 13.0854 1.44805 13.0854 1.68552Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1035 2.9594C13.1035 3.20751 12.917 3.40953 12.688 3.40953C12.459 3.40953 12.2725 3.20751 12.2725 2.9594C12.2725 2.7113 12.459 2.50928 12.688 2.50928C12.917 2.50928 13.1035 2.7113 13.1035 2.9594Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1214 4.23378C13.1214 4.49251 12.9284 4.7034 12.6879 4.7034C12.4474 4.7034 12.2544 4.49429 12.2544 4.23378C12.2544 3.97327 12.4474 3.76416 12.6879 3.76416C12.9284 3.76416 13.1214 3.97327 13.1214 4.23378Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1393 5.50962C13.1393 5.77899 12.9381 5.99874 12.6878 5.99874C12.4375 5.99874 12.2363 5.78076 12.2363 5.50962C12.2363 5.23848 12.4375 5.02051 12.6878 5.02051C12.9381 5.02051 13.1393 5.23848 13.1393 5.50962Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1573 6.784C13.1573 7.064 12.9479 7.29261 12.6878 7.29261C12.4277 7.29261 12.2183 7.06577 12.2183 6.784C12.2183 6.50223 12.4277 6.27539 12.6878 6.27539C12.9479 6.27539 13.1573 6.50223 13.1573 6.784Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1752 8.05789C13.1752 8.34852 12.9576 8.58599 12.6877 8.58599C12.4178 8.58599 12.2002 8.35029 12.2002 8.05789C12.2002 7.76548 12.4178 7.52979 12.6877 7.52979C12.9576 7.52979 13.1752 7.76548 13.1752 8.05789Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.1936 9.33373C13.1936 9.63499 12.9678 9.88132 12.6881 9.88132C12.4084 9.88132 12.1826 9.63676 12.1826 9.33373C12.1826 9.03069 12.4084 8.78613 12.6881 8.78613C12.9678 8.78613 13.1936 9.03069 13.1936 9.33373Z'
                  fill='#5039FF'
                />
                <path
                  d='M12.6895 11.1752C12.9786 11.1752 13.213 10.9213 13.213 10.6081C13.213 10.2949 12.9786 10.041 12.6895 10.041C12.4004 10.041 12.166 10.2949 12.166 10.6081C12.166 10.9213 12.4004 11.1752 12.6895 11.1752Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.2294 11.8825C13.2294 12.2068 12.9873 12.4691 12.688 12.4691C12.3886 12.4691 12.1465 12.2068 12.1465 11.8825C12.1465 11.5582 12.3886 11.2959 12.688 11.2959C12.9873 11.2959 13.2294 11.5582 13.2294 11.8825Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.2474 13.1583C13.2474 13.4933 12.9971 13.7644 12.6879 13.7644C12.3787 13.7644 12.1284 13.4933 12.1284 13.1583C12.1284 12.8234 12.3787 12.5522 12.6879 12.5522C12.9971 12.5522 13.2474 12.8234 13.2474 13.1583Z'
                  fill='#5039FF'
                />
                <path
                  d='M12.6893 15.0578C13.0082 15.0578 13.2667 14.7777 13.2667 14.4322C13.2667 14.0867 13.0082 13.8066 12.6893 13.8066C12.3704 13.8066 12.1118 14.0867 12.1118 14.4322C12.1118 14.7777 12.3704 15.0578 12.6893 15.0578Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.2832 15.7066C13.2832 16.0628 13.0166 16.3516 12.6877 16.3516C12.3589 16.3516 12.0923 16.0628 12.0923 15.7066C12.0923 15.3504 12.3589 15.0615 12.6877 15.0615C13.0166 15.0615 13.2832 15.3504 13.2832 15.7066Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.3375 19.5307C13.3375 19.9188 13.0463 20.2342 12.688 20.2342C12.3298 20.2342 12.0386 19.9188 12.0386 19.5307C12.0386 19.1426 12.3298 18.8271 12.688 18.8271C13.0463 18.8271 13.3375 19.1426 13.3375 19.5307Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.3554 20.8051C13.3554 21.2038 13.056 21.5281 12.6879 21.5281C12.3199 21.5281 12.0205 21.2038 12.0205 20.8051C12.0205 20.4063 12.3199 20.082 12.6879 20.082C13.056 20.082 13.3554 20.4063 13.3554 20.8051Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.3733 22.0809C13.3733 22.4903 13.0658 22.8234 12.6879 22.8234C12.31 22.8234 12.0024 22.4903 12.0024 22.0809C12.0024 21.6715 12.31 21.3384 12.6879 21.3384C13.0658 21.3384 13.3733 21.6715 13.3733 22.0809Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.3912 23.3553C13.3912 23.7753 13.0771 24.1173 12.6878 24.1173C12.2985 24.1173 11.9844 23.7771 11.9844 23.3553C11.9844 22.9335 12.2985 22.5933 12.6878 22.5933C13.0771 22.5933 13.3912 22.9335 13.3912 23.3553Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.4092 24.6292C13.4092 25.0616 13.0869 25.4107 12.6877 25.4107C12.2886 25.4107 11.9663 25.0616 11.9663 24.6292C11.9663 24.1968 12.2886 23.8477 12.6877 23.8477C13.0869 23.8477 13.4092 24.1968 13.4092 24.6292Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.4271 25.9055C13.4271 26.3485 13.0966 26.7065 12.6877 26.7065C12.2787 26.7065 11.9482 26.3485 11.9482 25.9055C11.9482 25.4625 12.2787 25.1045 12.6877 25.1045C13.0966 25.1045 13.4271 25.4625 13.4271 25.9055Z'
                  fill='#5039FF'
                />
                <path
                  d='M13.4455 27.1794C13.4455 27.6331 13.1069 27.9999 12.6881 27.9999C12.2693 27.9999 11.9307 27.6331 11.9307 27.1794C11.9307 26.7257 12.2693 26.3589 12.6881 26.3589C13.1069 26.3589 13.4455 26.7257 13.4455 27.1794Z'
                  fill='#5039FF'
                />
                <path
                  d='M23.0661 3.07278C25.1322 3.07278 26.7566 4.61987 26.7566 6.74822C26.7566 8.87658 25.1322 10.4449 23.0661 10.4502C21 10.4449 19.3657 8.91379 19.3706 6.74822C19.3657 4.62164 21 3.07278 23.0661 3.07278ZM21.3746 11.4746H23.4914V14.5758H30.7383V16.3905H21.3746V11.4746ZM23.0661 8.47962C23.9756 8.47962 24.6856 7.87886 24.6856 6.74822C24.6856 5.61759 23.9756 5.0381 23.0661 5.04519C22.1205 5.03987 21.4106 5.65835 21.4171 6.74822C21.4122 7.87886 22.1205 8.47962 23.0661 8.47962ZM30.4112 12.5113H28.3091V2.18848H30.4112V12.5113Z'
                  fill='white'
                />
                <path
                  d='M37.8132 6.33531C37.8132 8.31126 36.5127 9.70949 34.7247 9.71481C32.9318 9.70949 31.6509 8.31126 31.6558 6.33531C31.6509 4.33632 32.9301 2.95582 34.7247 2.96113C36.5127 2.95582 37.8132 4.33632 37.8132 6.33531ZM34.7247 7.8505C35.4281 7.8505 35.9123 7.33835 35.9172 6.33531C35.9123 5.29329 35.4298 4.77582 34.7247 4.78113C34 4.77582 33.5174 5.29329 33.5223 6.33531C33.5174 7.33835 34 7.8505 34.7247 7.8505ZM33.9067 10.5778H35.9778V11.7687H41.0457V10.5778H43.1217V16.4685H33.9067V10.5778ZM41.0457 14.6591V13.5515H35.9778V14.6591H41.0457ZM38.2958 2.43835H40.2327V5.0824H41.1373V2.18848H43.1217V10.0338H41.1373V6.92721H40.2327V9.93455H38.2958V2.43835Z'
                  fill='white'
                />
                <path
                  d='M44.1099 9.75587H56.2694V11.5599H53.5963V16.4706H51.4795V11.5599H48.8948V16.4706H46.8025V11.5599H44.1099V9.75587ZM54.9345 8.26903H45.5543V2.646H47.6466V6.46498H54.9345V8.26903Z'
                  fill='white'
                />
                <path
                  d='M56.8765 13.0517H69.0621V14.9054H56.8765V13.0517ZM68.6924 8.87121L67.778 10.7586C65.388 10.2624 63.7439 8.89957 62.89 7.12919C62.0213 8.88362 60.3723 10.2535 58.002 10.7586L57.112 8.87121C60.2889 8.27577 61.753 5.98083 61.7628 3.99957V2.99121H64.004V3.99957C63.9991 6.0145 65.4436 8.29172 68.6908 8.87121H68.6924Z'
                  fill='white'
                />
                <path d='M22.022 27.3284H20.625V20.9912H22.022V27.3284Z' fill='white' />
                <path
                  d='M26.4096 25.0831V20.9912H27.6087V27.3284H26.6173L23.9836 23.217H24.1815V27.3284H22.9824V20.9912H23.9639L26.5977 25.0831H26.4096Z'
                  fill='white'
                />
                <path
                  d='M34.7346 27.2982H33.3474L33.0644 25.7919L33.1772 25.8912H31.0539L31.1667 25.7919L30.8461 27.2982H29.5439L30.7709 22.1094C30.8461 21.7975 30.9312 21.5529 31.0261 21.3757C31.1209 21.2003 31.2534 21.0744 31.4219 21.0018C31.5921 20.9291 31.8211 20.899 32.1106 20.9114L33.4701 20.961L34.7346 27.2982ZM31.9127 22.3681L31.4023 24.7233L31.3271 24.6241H32.9417L32.8664 24.7233L32.4035 22.2281H32.0452C32.001 22.2281 31.9716 22.2387 31.9552 22.2582C31.9389 22.2777 31.9258 22.3149 31.9127 22.3681Z'
                  fill='white'
                />
                <path
                  d='M36.7418 27.3285H35.3447V20.9913L37.8656 20.9417C38.4316 20.9293 38.8651 21.0675 39.1629 21.3617C39.4622 21.6541 39.6111 22.0635 39.6111 22.5898V23.5485C39.6111 23.8622 39.5457 24.1475 39.4132 24.4062C39.2807 24.665 39.0844 24.8705 38.8226 25.0194C38.5609 25.1683 38.2419 25.2445 37.864 25.2445H36.552L36.7401 25.0141V27.3285H36.7418ZM36.7418 21.989V24.1546L36.5536 23.9543H38.0636C38.1519 23.9543 38.1961 23.9083 38.1961 23.8143V22.3665C38.1961 22.2726 38.1519 22.2265 38.0636 22.2265H36.5536L36.7418 21.9873V21.989Z'
                  fill='white'
                />
                <path
                  d='M41.6676 27.3285H40.2705V20.9913L42.7914 20.9417C43.3574 20.9293 43.7909 21.0675 44.0887 21.3617C44.388 21.6541 44.5369 22.0635 44.5369 22.5898V23.5485C44.5369 23.8622 44.4715 24.1475 44.3389 24.4062C44.2064 24.665 44.0101 24.8705 43.7484 25.0194C43.4866 25.1683 43.1677 25.2445 42.7898 25.2445H41.4778L41.6659 25.0141V27.3285H41.6676ZM41.6676 21.989V24.1546L41.4794 23.9543H42.9893C43.0777 23.9543 43.1218 23.9083 43.1218 23.8143V22.3665C43.1218 22.2726 43.0777 22.2265 42.9893 22.2265H41.4794L41.6676 21.9873V21.989Z'
                  fill='white'
                />
                <path
                  d='M50.1791 25.0797V20.9878H51.3782V27.325H50.3869L47.7531 23.2136H47.9511V27.325H46.752V20.9878H47.7335L50.3673 25.0797H50.1791Z'
                  fill='white'
                />
                <path
                  d='M56.0176 27.325H52.3467V20.9878H55.9718V22.2744H53.5556L53.7437 22.0741V23.6815L53.5556 23.4812H55.7837V24.7483H53.5556L53.7437 24.548V26.2351L53.5556 26.0349H56.0192V27.3215L56.0176 27.325Z'
                  fill='white'
                />
                <path
                  d='M58.9622 27.4242H58.0936C57.6715 27.4242 57.364 27.3286 57.1693 27.1354C56.9746 26.9422 56.8765 26.6232 56.8765 26.1767V20.9878H58.2637V25.9569C58.2637 26.0508 58.3079 26.0969 58.3962 26.0969H59.3597C59.4481 26.0969 59.4922 26.0508 59.4922 25.9569V20.9878H60.814V25.9569C60.814 26.0508 60.8582 26.0969 60.9465 26.0969H61.9281C62.0164 26.0969 62.0606 26.0508 62.0606 25.9569L62.0409 20.9878H63.4282L63.4462 26.1767C63.4462 26.6232 63.348 26.9422 63.1533 27.1354C62.9587 27.3286 62.6495 27.4242 62.2291 27.4242H61.3424C60.9204 27.4242 60.6145 27.3286 60.4214 27.1354C60.23 26.9422 60.1335 26.6232 60.1335 26.1767H60.1711C60.1711 26.6232 60.0762 26.9422 59.8881 27.1354C59.7 27.3286 59.3908 27.4242 58.9639 27.4242H58.9622Z'
                  fill='white'
                />
                <path
                  d='M65.8853 23.4723H66.7261C67.2921 23.4723 67.7256 23.6211 68.0234 23.9171C68.3227 24.213 68.4716 24.6242 68.4716 25.1487V25.7282C68.4716 26.2545 68.3227 26.6621 68.0234 26.9563C67.724 27.2487 67.2921 27.3887 66.7261 27.3745L64.4899 27.3249V26.0383H66.9257C67.014 26.0383 67.0582 25.9923 67.0582 25.8983V24.9502C67.0582 24.8563 67.014 24.8102 66.9257 24.8102H66.0848C65.5188 24.8102 65.0853 24.6614 64.7876 24.3654C64.4882 24.0695 64.3394 23.6583 64.3394 23.1338V22.5844C64.3394 22.0581 64.4882 21.6505 64.7876 21.3563C65.087 21.0639 65.5188 20.9239 66.0848 20.9363L68.2458 20.9859V22.2725H65.8869C65.7986 22.2725 65.7544 22.3186 65.7544 22.4125V23.3305C65.7544 23.4244 65.7986 23.4705 65.8869 23.4705L65.8853 23.4723Z'
                  fill='white'
                />
              </g>
            </g>
            <defs>
              <clipPath id='clip0_673_43176'>
                <rect width='69' height='28' fill='white' />
              </clipPath>
              <clipPath id='clip1_673_43176'>
                <rect width='69.062' height='28' fill='white' />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <></>
        )}
        <h2 className='h-20px app-sidebar-logo-minimize'>
          <i className='ki-duotone ki-text-align-left fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
          </i>
        </h2>
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-primary btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName='double-left' className='fs-2 rotate-180' />
        </div>
      )}
    </div>
  )
}

export {SidebarLogo}
