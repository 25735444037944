import axios, {AxiosResponse, AxiosStatic} from 'axios'
import {getAuth, removeAuth} from '../auth'

const BASE_URL = process.env.REACT_APP_BASE_URL || ''

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const apiService = setupAxios(axios)

// const refreshAccessToken = async (): Promise<string | null> => {
//   try {
//     const auth = getAuth()
//     if (auth && auth.refresh_token) {
//       const response = await getRefreshAccessToken(auth.refresh_token)
//       return response?.data?.token
//     }
//     return null
//   } catch (error) {
//     throw error
//   }
// }

type TypeConfigHeader = {
  key: string | undefined
  value: string | undefined
}

function setConfigHeaders(setting: TypeConfigHeader) {
  if (setting && setting?.key && apiService) {
    //@ts-ignore
    apiService.defaults.headers[setting?.key || ''] = setting?.value
  }
}

function setupAxios(axiosClient: any): AxiosStatic {
  axiosClient.defaults.baseURL = BASE_URL
  const lang = localStorage.getItem(I18N_CONFIG_KEY)
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Interceptor for request
  axiosClient.interceptors.request.use(
    (config: {headers: {Authorization: string; method: string}; baseURL: string}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
        // @ts-ignore
        config.headers.Localization = `${lang ? JSON.parse(lang)?.selectedLang : 'ko'}`
        // @ts-ignore
        config.headers.Timezone = `${timezoneOffset}`
      }
      return config
    },
    (error: any) => Promise.reject(error)
  )

  // Interceptor for response
  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return response?.data
    },
    async (error: any) => {
      // window.location.href = "/auth";
      const originalRequest = error.config

      if (error.response && error.response.status === 401 && originalRequest._retry) {
        removeAuth()
        window.location.href = '/auth'
        // const { code } = await postNavigateLogout();
        // originalRequest._retry = true;
        // try {
        //   const newAccessToken = await refreshAccessToken();
        //   if (newAccessToken) {
        //     // Update to save accessToken
        //     originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        //     return axiosClient(originalRequest);
        //   }
        // } catch (refreshError) {
        //   // Handle error refresh token
        return Promise.reject('Handle error refresh token')
        // }
      }
      // return Promise.reject(error);
      return Promise.resolve(error?.response?.data)
      //return error?.response?.data;
    }
  )
  return axiosClient
}

export {apiService, setConfigHeaders}
