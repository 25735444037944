import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metacrew/assets/ts/_utils'
import {WithChildren} from '../../_metacrew/helpers'
import {MasterLayout} from '../../_metacrew/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = () => {
  const WellComePage = lazy(() => import('../modules/welcome/index'))
  const SDKManagementPage = lazy(() => import('../modules/sdk-management/index'))
  const TicketManagementPage = lazy(() => import('../modules/ticket-management/index'))
  const TicketDetailPage = lazy(
    () => import('../modules/ticket-management/components/NewIssueDetailPage')
  )
  const UserPage = lazy(() => import('../modules/user/index'))
  const RewardPage = lazy(() => import('../modules/reward/index'))
  const UserStatisticsPage = lazy(() => import('../modules/user-statistics/index'))
  const PushStatisticsPage = lazy(() => import('../modules/push-statistics/index'))
  const SettingsPage = lazy(() => import('../modules/settings/index'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/welcome' />} />
        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='list-user'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='reward/*'
          element={
            <SuspensedView>
              <RewardPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-statistics'
          element={
            <SuspensedView>
              <UserStatisticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='push-statistics'
          element={
            <SuspensedView>
              <PushStatisticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='welcome'
          element={
            <SuspensedView>
              <WellComePage className={''} />
            </SuspensedView>
          }
        />
        <Route
          path='sdk-management'
          element={
            <SuspensedView>
              <SDKManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticket-management'
          element={
            <SuspensedView>
              <TicketManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticket-management/:id'
          element={
            <SuspensedView>
              <TicketDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings-management/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
