/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  // Get full permissions of account login

  return (
    <>
      <SidebarMenuItem
        icon='abstract-26'
        fontIcon='bi-app-indicator'
        to='/welcome'
        title={intl.formatMessage({id: 'HOW_TO_INTEGRATE'})}
      />
      <SidebarMenuItem
        icon='chart-simple'
        fontIcon='bi-app-indicator'
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU_DASHBOARD'})}
      />

      <SidebarMenuItem
        icon='user'
        fontIcon='bi-app-indicator'
        to='/list-user'
        title={intl.formatMessage({id: 'MENU_USER'})}
      />
      <SidebarMenuItemWithSub
        icon='gift'
        fontIcon='bi-app-indicator'
        to='/reward'
        title={intl.formatMessage({id: 'MENU_REWARD'})}
      >
        <SidebarMenuItem
          to='/reward/user-reward'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU_REWARD.USER_REWARD'})}
        />

        <SidebarMenuItem
          to='/reward/settings-reward'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU_REWARD.SETTINGS_REWARD'})}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        icon='graph-up'
        fontIcon='bi-app-indicator'
        to='/user-statistics'
        title={intl.formatMessage({id: 'MENU_USER_STATISTICS'})}
      />
      <SidebarMenuItem
        icon='notification-on'
        fontIcon='bi-app-indicator'
        to='/push-statistics'
        title={intl.formatMessage({id: 'MENU_PUSH_STATISTICS'})}
      />
      <SidebarMenuItem
        to='/sdk-management'
        title={intl.formatMessage({id: 'MENU_SDK_MANAGEMENT'})}
        icon='data'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/ticket-management'
        title={intl.formatMessage({id: 'MENU_TICKET_MANAGEMENT'})}
        icon='questionnaire-tablet'
        fontIcon='bi-layers'
      />
      <SidebarMenuItemWithSub
        to='/settings-management'
        title={intl.formatMessage({id: 'SETTING_MANAGEMENT'})}
        icon='setting-4'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem
          to='/settings-management/notification-setting'
          title={intl.formatMessage({id: 'NOTIFICATION_SETTING'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
