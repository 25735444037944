import {apiService} from '../../../helpers/axios'
import {
  PaginationResponse,
  ParamsRequestGetList,
  ResponseApi,
  ResponseData,
} from '../../models/_public'

export function fetchNotification(
  params: ParamsRequestGetList
): Promise<ResponseApi<ResponseData<any[]> & PaginationResponse>> {
  return apiService.get('/crm-notification', {params})
}

export function fetchDetailNotification(id: any): Promise<ResponseApi<any>> {
  return apiService.get(`/crm-notification/${id}`)
}

export function postCancelNotification(idNotification: string): Promise<ResponseApi<Notification>> {
  return apiService.post(`/notifications/schedule/${idNotification}`, {})
}
export function postMarkAllAsRead(params: any): Promise<ResponseApi<any>> {
  return apiService.post(`/crm-notification/read`, params)
}

export function postUpdateStatusNotification(
  idNotification: string,
  status: boolean | number
): Promise<ResponseApi<Notification>> {
  return apiService.post(`/notifications/${idNotification}`, {status: Number(status)})
}

export function postDeleteNotification(
  idNotification: string,
  dataForm: Partial<any> | any = {}
): Promise<ResponseApi<Notification>> {
  return apiService.delete(`/notifications/${idNotification}`, {data: dataForm})
}
