import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useCallback, useState} from 'react'
import {IParamsSearch} from '../../../api/models/_public'
import {fetchNotification} from '../../../api/requests/crm-notification/_notification'
import {QUERIES} from '../../crud-helper/consts'

const NUMBER_PAGE_DEFAULT: number = 1

const useListNotification = () => {
  const queryClient = useQueryClient()

  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    page: NUMBER_PAGE_DEFAULT,
    keyword: '',
    limit: 10,
    status: '',
    start_date: '',
    end_date: '',
    tab: 'all',
  })

  const fetchPaginatedData = async ({queryKey, pageParam = NUMBER_PAGE_DEFAULT}: any) => {
    const res = await fetchNotification({page: pageParam, ...queryKey[1]})
    return res?.data
  }

  // eslint-disable-next-line
  const {status, data, error, isFetching, isPreviousData, refetch, ...root} = useQuery({
    queryKey: [QUERIES.NOTIFICATION_LIST, searchParams],
    queryFn: fetchPaginatedData,
    keepPreviousData: true,
    staleTime: 5000,
    cacheTime: 5000,
  })

  const handleChangeParams = useCallback((params: IParamsSearch) => {
    setSearchParams((prevState) => {
      return {...prevState, ...params}
    })
  }, [])

  const handleSetQueryDataItem = useCallback(
    (itemId: any, isActive: string | number | boolean) => {
      queryClient.setQueryData(
        [QUERIES.NOTIFICATION_SCHEDULE_LIST, searchParams],
        (_oldData: any) => {
          return {
            ..._oldData,
            items: _oldData?.items?.map((item: any) =>
              item?.id === itemId ? {...item, status: isActive} : item
            ),
          }
        }
      )
    },
    [queryClient, searchParams]
  )

  return {
    data: data,
    //@ts-ignore

    status,
    error,
    isFetching,
    isPreviousData,
    refetch,
    searchParams,
    handleChangeParams,
    handleSetQueryDataItem,
  }
}

export default useListNotification
