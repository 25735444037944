import {apiService} from '../../../helpers/axios'
import {
  PaginationResponse,
  ParamsRequestGetList,
  ResponseApi,
  ResponseData,
} from '../../models/_public'

export function fetchOverallStatistics(
  params: ParamsRequestGetList
): Promise<ResponseApi<ResponseData<any[]> & PaginationResponse>> {
  return apiService.get('/reports/push/overall', {params})
}
