import {AxiosResponse} from 'axios'
import {apiService} from '../../helpers/axios'
import {AuthModel} from '../models/_auth'
import {IUser} from '../models/_user'
import {ResponseApi} from '../models/_public'

export function getUserByToken(token: string): Promise<AxiosResponse<ResponseApi<IUser>, any>> {
  return apiService.post<ResponseApi<IUser>>('/auth/login', {
    token: token,
  })
}

export function postLogin(params: {email: string; password: string}): Promise<ResponseApi<any>> {
  return apiService.post('/auth/login', params)
}

export function postRegister(params: {
  email: string
  password: string
}): Promise<ResponseApi<AuthModel>> {
  return apiService.post('/auth/register', params)
}

export function postLogout(): Promise<ResponseApi<IUser>> {
  return apiService.post('/auth/logout', {})
}

export function getVerifyUserByAccessToken(): Promise<ResponseApi<IUser>> {
  return apiService.get('/info', {})
}

export function requestPassword(params: {email: string}): Promise<ResponseApi<IUser>> {
  return apiService.get('/info', {params})
}

export function getRefreshAccessToken(refresh_token: string): Promise<ResponseApi<AuthModel>> {
  return apiService.post('/auth/refresh-token', {
    refresh_token: refresh_token,
  })
}

export function updateUserFCMToken({token, id}: any): Promise<ResponseApi<IUser>> {
  return apiService.post(`/info`, {firebase_token: token, id: id})
}
