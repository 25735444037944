import {Badge} from 'antd'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {usePageData} from '../../core'

const itemClass = 'ms-1 ms-lg-3'

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative rounded-circle border '
const Navbar = () => {
  const {notificationCount} = usePageData()
  return (
    <div className='app-navbar flex-shrink-0 ms-auto'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='notification' iconType='outline' className={btnIconClass} />
          <div className='position-absolute top-0 ' style={{right: 5}}>
            <Badge
              size='small'
              count={notificationCount}
              styles={{
                indicator: {
                  fontSize: 10,
                  backgroundColor: '#775be7',
                },
              }}
            ></Badge>
          </div>
        </div>
        <HeaderNotificationsMenu />
      </div>
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
