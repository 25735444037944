import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {I18nProvider} from '../_metacrew/i18n/i18nProvider'
import {MasterInit} from '../_metacrew/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metacrew/layout/core'
import {AuthInit} from './modules/auth'

const App = () => {
  // useEffect(() => {
  //   const src = `//cdn.jsdelivr.net/npm/eruda`
  //   loadJS(src).then(() => {
  //     if ((window as any).eruda && (window as any).eruda._isInit) return
  //     ;(window as any).eruda.init()
  //   })
  //   return () => {
  //     ;(window as any).eruda && (window as any).eruda._isInit && (window as any).eruda.destroy()
  //   }
  // }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          style={{zIndex: 999999999}}
          toastStyle={{borderRadius: '10px', margin: '10px'}}
        />
      </I18nProvider>
    </Suspense>
  )
}

export {App}
