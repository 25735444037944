import {createRoot} from 'react-dom/client'
// Axios
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
// Apps
import './_metacrew/assets/fonticon/fonticon.css'
import './_metacrew/assets/keenicons/duotone/style.css'
import './_metacrew/assets/keenicons/outline/style.css'
import './_metacrew/assets/keenicons/solid/style.css'
import {MetaCrewI18nProvider} from './_metacrew/i18n/MetaCrewi18n'

import './_metacrew/assets/sass/custom/custom.scss'
import './_metacrew/assets/sass/plugins.scss'
import './_metacrew/assets/sass/style.react.scss'
import './_metacrew/assets/sass/style.scss'
import {AuthProvider} from './app/modules/auth'
import {AppRoutes} from './app/routing/AppRoutes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 1,
    },
  },
})
const container = document.getElementById('root')
serviceWorkerRegistration.register()
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetaCrewI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetaCrewI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
