import {
  fetchDetailNotification,
  postMarkAllAsRead,
} from '../../../api/requests/crm-notification/_notification'

const useActionNotification = () => {
  const getDetailNotification = async (id: any) => {
    const res = await fetchDetailNotification(id)
    return res
  }
  const markAllAsRead = async () => {
    const res = await postMarkAllAsRead({is_read_all: '1'})
    return res
  }

  return {
    getDetailNotification,
    markAllAsRead,
  }
}

export default useActionNotification
