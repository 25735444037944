import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'
import {Badge} from 'react-bootstrap'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_perPage=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'items_perPage'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function getStartDateEndDate(numberDate = 7) {
  const date = new Date()
  const endDate =
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate())
  date.setDate(date.getDate() - numberDate + 1)

  const startDate =
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate())

  return [startDate, endDate]
}

function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value)
}

function extractWidthAndHeightFromURL(url: string, char: string = '') {
  const regex = /(\d+)-(\d+)\.(jpg|jpeg|png|gif|bmp|webp)/i
  const match = url.match(regex)

  if (match) {
    const width = parseInt(match[1])
    const height = parseInt(match[2])
    return char ? `${width}${char}${height}` : [width, height]
  } else {
    return char ? '' : [0, 0] // Returns [0,0] if no results are found
  }
}

function formatNumber(number: any) {
  if (number >= 1000) {
    const formattedNumber = new Intl.NumberFormat().format(number)
    return formattedNumber
  } else {
    return number.toString()
  }
}
export const shadeCellWithColor = (percent: any, color = '#3f83a3') => {
  const rate = 1.0 - Math.ceil(percent / 10) / 10
  const f = parseInt(color.slice(1), 16)
  const t = rate < 0 ? 0 : 255
  const p = rate < 0 ? rate * -1 : rate
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff

  const newColor = (
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)
  // Ensure that the result always has two characters
  return `#${newColor}`
}

export const getPercentage = (total: any, value: any) => {
  return total ? Math.round((value / total) * 100 * 100) / 100 : total
}

export const bytesToSize = (bytes: number, decimals = 2) => {
  if (!Number(bytes)) {
    return '0 Bytes'
  }

  const kbToBytes = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes))

  return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${sizes[index]}`
}

const getTypeOfFile = (typeString: string) => {
  const array = typeString.split('/')
  if (array[0] === 'image') {
    return 'image'
  }
  if (array[array.length - 1] === 'pdf') {
    return 'pdf'
  }
  if (array[array.length - 1].includes('document')) {
    return 'document'
  }
  return array[array.length - 1]
}

const getnameOfFile = (name: string, length = 35) => {
  const array = name.split('/')
  if (array[array.length - 1]?.length > length)
    return array[array.length - 1].slice(0, length) + '...'

  return array[array.length - 1]
}
const renderStatus = (status: any, intl: any, height = 30, minWidth = 60) => {
  if (status === 'open') {
    return (
      <Badge
        bg={'auto'}
        style={{backgroundColor: '#009ef7', height: height, padding: 10, minWidth: minWidth}}
        className='text-white d-flex justify-content-center align-items-center'
      >
        {intl.formatMessage({id: 'OPEN'})}
      </Badge>
    )
  }
  return (
    <Badge
      style={{height: height, padding: 10, minWidth: minWidth}}
      bg='secondary'
      className='text-white d-flex justify-content-center align-items-center'
    >
      {intl.formatMessage({id: 'CLOSE'})}
    </Badge>
  )
}

const loadJS = (src: string, attr?: {[key: string]: any}) => {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script')
    tag.setAttribute('src', src)
    tag.setAttribute('type', 'text/javascript')
    if (attr) {
      for (var key in attr) {
        tag.setAttribute(key, attr[key])
      }
    }
    tag.onload = resolve

    tag.onerror = reject

    document.head.appendChild(tag)
  })
}
export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  getStartDateEndDate,
  getKeyByValue,
  extractWidthAndHeightFromURL,
  formatNumber,
  getTypeOfFile,
  getnameOfFile,
  renderStatus,
  loadJS,
}
