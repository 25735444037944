import {initializeApp} from 'firebase/app'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useAuth} from '../../../../app/modules/auth'
import {usePageData} from '../../../layout/core'
import {updateUserFCMToken} from '../../../api/requests/_auth'
import useActionNotification from '../../../helpers/hooks/crm-notification/useActionNotifications'
import {MenuComponent} from '../../../assets/ts/components/MenuComponent'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {renderStatus} from '../../../helpers/crud-helper/helpers'
const NotificationComponent = () => {
  const [data, setData] = React.useState<any>()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const intl = useIntl()

  const {updateTriggerLoadListNotification, triggerLoadListNotification} = usePageData()
  const {getDetailNotification} = useActionNotification()
  const handleOnClickNotification = async () => {
    if (!data?.messageId) return
    const {code, error, data: response} = await getDetailNotification(data?.messageId)
    if (code === 1) {
      MenuComponent.hideDropdowns(undefined)
      updateTriggerLoadListNotification && updateTriggerLoadListNotification(1)
      if (
        (response?.type === 'release_new_sdk' || response?.type === 'update_release_new_sdk') &&
        response?.sdk_detail
      ) {
        navigate(`/sdk-management`, {state: {sdk_detail: response?.sdk_detail}})
      }
      if (
        (response?.type === 'new_comment' || response?.type === 'update_ticket_status') &&
        response?.data?.ticket_id
      ) {
        if (response?.type === 'update_ticket_status') {
          navigate(`/ticket-management/${response?.data?.ticket_id}`, {
            state: {isComment: false},
          })
        }
        if (response?.type === 'new_comment') {
          navigate(`/ticket-management/${response?.data?.ticket_id}`, {
            state: {isComment: true},
          })
        }
      }
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }
  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STOREGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_MESSAGE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    }

    const app = initializeApp(firebaseConfig)
    const messaging = getMessaging(app)

    const getMessagingToken = async () => {
      let currentToken = ''
      if (!messaging) return
      try {
        currentToken = await getToken(messaging)
        if (
          (currentUser && !currentUser.firebase_token) ||
          (currentUser && currentUser.firebase_token !== currentToken)
        ) {
          //eslint-disable-next-line
          const data = await updateUserFCMToken({token: currentToken, id: currentUser.id})
        }
      } catch (error) {
        console.log('An error occurred while retrieving token. ', error)
      }
      return currentToken
    }
    if (Notification.permission === 'granted') {
      if (messaging) {
        getMessagingToken()
      }
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.')
          window.location.reload()
        } else {
          console.log('Unable to get permission to notify.')
        }
      })
    }

    // eslint-disable-next-line
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received:', payload)
      const {title, body, icon}: any = payload.notification || payload.data
      const data = payload.data
      const messageId = payload.data?.notification_id
      setData({title, body, data, icon, messageId})
      MenuComponent.hideDropdowns(undefined)
      // eslint-disable-next-line

      updateTriggerLoadListNotification &&
        updateTriggerLoadListNotification((triggerLoadListNotification || 0) + 1)
      // Handle the incoming notification
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'backgroundMessage') {
        console.log('Received background message:', event.data.data)
        updateTriggerLoadListNotification &&
          updateTriggerLoadListNotification((triggerLoadListNotification || 0) + 1)
      }
    }

    navigator.serviceWorker.addEventListener('message', handleMessage)

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line
  }, [])
  const Msg = () => {
    return (
      <div className='d-flex align-items-center gap-3' onClick={handleOnClickNotification}>
        {data?.data?.type?.includes('ticket') && (
          <img className='h-35px ' src={'/media/logos/logo-ticket.svg'} alt='' />
        )}
        {data?.data?.type?.includes('comment') && (
          <img className='h-35px ' src={'/media/logos/logo-comment.svg'} alt='' />
        )}
        {data?.data?.type?.includes('sdk') && (
          <img className='h-35px ' src={'/media/logos/logo-sdk.svg'} alt='' />
        )}
        {!data?.data?.type && <img className='h-20px ' src={'/media/logos/logo-dark.svg'} alt='' />}
        <div className='d-flex flex-column'>
          <p className='text-gray-800 fs-6 fw-bolder mb-0'> {data?.title}</p>
          {data?.data?.from_status && data?.data?.to_status ? (
            <div className='d-flex align-items-center fs-7 text-gray-400 mb-0'>
              <p className='fs-7 text-gray-400 mb-0 me-2'>From status:</p>{' '}
              {renderStatus(data?.data?.from_status, intl, 20, 30)}
              <p className='mx-2 my-auto nowrap white-space-nowrap' style={{whiteSpace: 'nowrap'}}>
                {' '}
                {'to'}
              </p>
              {renderStatus(data?.data?.to_status, intl, 20, 30)}
            </div>
          ) : (
            <p className='fs-7 text-gray-400 mb-0'> {data?.body}</p>
          )}
        </div>
        {/* eslint-disable-next-line */}
      </div>
    )
  }
  useEffect(() => {
    if (!data) return

    toast(<Msg />, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      progressStyle: {
        color: 'red',
      },
      style: {width: 320, borderRadius: 8},
    })
    // eslint-disable-next-line
  }, [data])

  return <div></div>
}

export default NotificationComponent
