/* eslint-disable jsx-a11y/anchor-is-valid */
import {Badge} from 'antd'
import clsx from 'clsx'
import {FC, useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import useActionNotification from '../../../helpers/hooks/crm-notification/useActionNotifications'
import useListNotification from '../../../helpers/hooks/crm-notification/useListNotifications'
import {usePageData} from '../../../layout/core'
import {useNavigate} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingNotification from './LoadingNotification'
import {MenuComponent} from '../../../assets/ts/components/MenuComponent'
import {useIntl} from 'react-intl'
import {renderStatus} from '../../../helpers/crud-helper/helpers'

const HeaderNotificationsMenu: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('all')
  const {triggerLoadListNotification} = usePageData()
  const [dataShow, setDataShow] = useState<any>()
  const timeoutRef = useRef<any>(null)
  const [searchParams, setSearchParams] = useState<any>({
    keyword: '',
    limit: 10,
    start_date: '',
    end_date: '',
  })
  const {updateNotificationCount, updateTriggerLoadListNotification, notificationCount} =
    usePageData()
  const {data, isFetching, refetch, handleChangeParams} = useListNotification()

  const {getDetailNotification, markAllAsRead} = useActionNotification()

  const convertDateTimeToString = (dateString: string) => {
    const targetDate = new Date(dateString)

    // Calculate the time difference in milliseconds (7200000 = convert utc+7 to utc+9)
    const differenceMillis: number = new Date().getTime() + 7200000 - targetDate.getTime()

    // Convert milliseconds to minutes

    const seconds = Math.floor(differenceMillis / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)
    const years = Math.floor(days / 365)

    if (years > 0) {
      return years === 1 ? 'a year ago' : `${years} years ago`
    } else if (months > 0) {
      return months === 1 ? 'a month ago' : `${months} months ago`
    } else if (days > 0) {
      return days === 1 ? 'a day ago' : `${days} days ago`
    } else if (hours > 0) {
      return hours === 1 ? 'an hour ago' : `${hours} hours ago`
    } else if (minutes > 0) {
      return minutes === 1 ? 'a minute ago' : `${minutes} minutes ago`
    } else {
      return 'just now'
    }
  }

  const onChangeTab = (tab: any) => {
    if (isFetching) return
    setDataShow([])
    setSearchParams((_prev: any) => ({
      ..._prev,
      page: 1,
      tab: tab,
    }))
  }

  const handleOnClickNotification = async (item: any) => {
    const {code, error, data} = await getDetailNotification(item?.id)
    if (code === 1) {
      MenuComponent.hideDropdowns(undefined)
      setSelectedTab((prev: any) => selectedTab)
      updateTriggerLoadListNotification && updateTriggerLoadListNotification(1)
      if (
        (data?.type === 'release_new_sdk' || data?.type === 'update_release_new_sdk') &&
        data?.sdk_detail
      ) {
        navigate(`/sdk-management`, {state: {sdk_detail: data?.sdk_detail}})
      }

      if (
        (data?.type === 'new_comment' || data?.type === 'update_ticket_status') &&
        data?.data?.ticket_id
      ) {
        if (data?.type === 'update_ticket_status') {
          navigate(`/ticket-management/${data?.data?.ticket_id}`)
        }
        if (data?.type === 'new_comment') {
          navigate(`/ticket-management/${data?.data?.ticket_id}`, {
            state: {isComment: true},
          })
        }
      }
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }

  const handleLoadMore = () => {
    setSearchParams((_prev: any) => ({
      ..._prev,
      page: data && data.meta?.currentPage + 1,
    }))
  }
  const handleMarkAllAsRead = async () => {
    if (notificationCount <= 0) return
    const {code, error} = await markAllAsRead()
    if (code === 1) {
      refetch()
    } else {
      for (const element of error || []) {
        toast(element?.message || '')
      }
    }
  }
  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current)
    }
    timeoutRef.current = setTimeout(() => handleChangeParams(searchParams), 0)
  }, [handleChangeParams, searchParams])
  useEffect(() => {
    if (data && data.meta?.currentPage !== 1) {
      setDataShow((prev: any) => {
        const cloneData = {...prev}
        cloneData.meta = data?.meta
        const newData = cloneData.items.push(...data?.items)
        return {...prev, ...newData}
      })
    } else {
      setDataShow(data)
    }
  }, [data])
  useEffect(() => {
    if (
      !triggerLoadListNotification ||
      (triggerLoadListNotification && triggerLoadListNotification <= 0)
    )
      return
    handleChangeParams({page: 1, tab: selectedTab, limit: 10})
    setDataShow([])
    refetch()
    // eslint-disable-next-line
  }, [triggerLoadListNotification])
  useEffect(() => {
    updateNotificationCount(dataShow?.extra?.all?.unread || 0)
    if (dataShow?.extra?.all?.unread > 0) {
      document.title = `(${dataShow?.extra?.all?.unread}) 인앱뉴스 | IN APP NEWS | METACREW`
    } else {
      document.title = `인앱뉴스 | IN APP NEWS | METACREW`
    }
    // eslint-disable-next-line
  }, [dataShow])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top bg-info'
        style={{background: 'linear-gradient(90deg, #6f4dfa 0%, rgba(11, 7, 29, 0) 84.39%)'}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({id: 'NOTIFICATIONS'})}
          <span className='fs-8 opacity-75 ps-3'>
            {dataShow?.extra?.all?.total} {intl.formatMessage({id: 'NOTIFICATIONS'})}{' '}
            <span className='ms-2'>
              {dataShow?.extra?.all?.unread} {intl.formatMessage({id: 'UNREAD'})}
            </span>
          </span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item position-relative' onClick={() => onChangeTab('all')}>
            <a
              className={`nav-link text-white opacity-75 opacity-state-100 pb-4 ${
                selectedTab === 'all' && 'active'
              }`}
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              {intl.formatMessage({id: 'ALL'})}
            </a>

            {dataShow?.extra?.all?.unread > 0 && (
              <div className='position-absolute ' style={{right: -5, top: -3}}>
                <Badge
                  size='small'
                  count={dataShow?.extra?.all?.unread}
                  styles={{
                    indicator: {
                      fontSize: 10,
                      border: 'none',
                      boxShadow: '0 0 0 1px #4419f1',
                      backgroundColor: '#4419f1',
                    },
                  }}
                ></Badge>
              </div>
            )}
          </li>

          <li className='nav-item position-relative' onClick={() => onChangeTab('sdk')}>
            <a
              className={`nav-link text-white opacity-75 opacity-state-100 pb-4 ${
                selectedTab === 'sdk' && 'active'
              }`}
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              {intl.formatMessage({id: 'SDK_VERSION'})}
            </a>
            {dataShow?.extra?.sdk?.unread > 0 && (
              <div className='position-absolute ' style={{right: -5, top: -3}}>
                <Badge
                  size='small'
                  count={dataShow?.extra?.sdk?.unread}
                  styles={{
                    indicator: {
                      fontSize: 10,
                      border: 'none',
                      boxShadow: '0 0 0 1px #4419f1',
                      backgroundColor: '#4419f1',
                    },
                  }}
                ></Badge>
              </div>
            )}
          </li>

          <li className='nav-item position-relative'>
            <a
              className={`nav-link text-white opacity-75 opacity-state-100 pb-4 ${
                selectedTab === 'ticket_issue' && 'active'
              }`}
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
              onClick={() => onChangeTab('ticket_issue')}
            >
              {intl.formatMessage({id: 'TITLE_TICKET_ISSUE'})}
            </a>
            {dataShow?.extra?.ticket_issue?.unread > 0 && (
              <div className='position-absolute ' style={{right: -5, top: -3}}>
                <Badge
                  size='small'
                  count={dataShow?.extra?.ticket_issue?.unread}
                  styles={{
                    indicator: {
                      fontSize: 10,
                      border: 'none',
                      boxShadow: '0 0 0 1px #4419f1',
                      backgroundColor: '#4419f1',
                    },
                  }}
                ></Badge>
              </div>
            )}
          </li>

          <li className='nav-item position-relative'>
            <a
              className={`nav-link text-white opacity-75 opacity-state-100 pb-4 ${
                selectedTab === 'comment' && 'active'
              }`}
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
              onClick={() => onChangeTab('comment')}
            >
              {intl.formatMessage({id: 'COMMENT'})}
            </a>
            {dataShow?.extra?.comment?.unread > 0 && (
              <div className='position-absolute ' style={{right: -5, top: -3}}>
                <Badge
                  size='small'
                  count={dataShow?.extra?.comment?.unread}
                  styles={{
                    indicator: {
                      fontSize: 10,
                      border: 'none',
                      boxShadow: '0 0 0 1px #4419f1',
                      backgroundColor: '#4419f1',
                    },
                  }}
                ></Badge>
              </div>
            )}
          </li>
        </ul>
      </div>

      <InfiniteScroll
        dataLength={dataShow?.items?.length || 0}
        next={handleLoadMore}
        scrollableTarget='scrollableDiv'
        hasMore={dataShow && dataShow?.items?.length < dataShow?.meta?.total ? true : false}
        loader={''} // loader={<LoadingListNotify />}
        scrollThreshold={'50%'}
      >
        <div className='tab-content min-h-325px'>
          <div className='scroll-y mh-325px' id='scrollableDiv'>
            {isFetching && <LoadingNotification />}
            {dataShow &&
              dataShow?.items?.map((item: any, index: number) => (
                <div
                  onClick={() => handleOnClickNotification(item)}
                  key={`alert${index}`}
                  className={`d-flex flex-stack px-8 py-4 position-relative bg-hover-light-info`}
                >
                  <div className='d-flex align-items-center'>
                    <div className={`symbol me-4 `}>
                      <span
                        className={clsx(
                          'symbol-label',
                          `bg-light-${item.title.en} ${item?.is_read === 0 && 'text-info'}`
                        )}
                      >
                        {item?.type?.includes('ticket') && (
                          <img className='h-35px ' src={'/media/logos/logo-ticket.svg'} alt='' />
                        )}
                        {item?.type?.includes('comment') && (
                          <img className='h-35px ' src={'/media/logos/logo-comment.svg'} alt='' />
                        )}
                        {item?.type?.includes('sdk') && (
                          <img className='h-35px ' src={'/media/logos/logo-sdk.svg'} alt='' />
                        )}
                        {!item?.type && (
                          <img className='h-15px ' src={'/media/logos/logo-sdk.svg'} alt='' />
                        )}
                      </span>
                    </div>

                    <div className='mb-6 me-2 position-relative'>
                      <a
                        href='#'
                        className={`fs-6  text-hover-primary fw-bolder ${
                          item?.is_read === 0 ? 'text-info' : 'text-gray-800'
                        }`}
                      >
                        {item.title.en}
                      </a>
                      {item.type === 'update_ticket_status' ? (
                        <div className='d-flex align-items-center fs-7 text-gray-400 mb-0'>
                          <p className='fs-7 text-gray-400 mb-0 me-2'>From status:</p>{' '}
                          {renderStatus(item?.data?.from_status, intl, 20, 40)}
                          <span className='mx-2'> {'to'}</span>
                          {renderStatus(item?.data?.to_status, intl, 20, 40)}
                        </div>
                      ) : (
                        <div
                          className={` fs-7 ${item?.is_read === 0 ? 'text-info' : 'text-gray-400'}`}
                          style={{overflowWrap: 'anywhere'}}
                        >
                          {item.body.en}
                        </div>
                      )}

                      <span
                        className={`badge badge-light fs-8 position-absolute ${
                          item?.is_read === 0 && 'text-info'
                        }`}
                        style={{bottom: -24}}
                      >
                        {convertDateTimeToString(item?.created_at)}
                      </span>
                    </div>
                  </div>

                  {item?.is_read === 0 && (
                    <span
                      className='badge badge-circle bg-info badge-light fs-4'
                      style={{
                        width: 10,
                        height: 10,
                        marginLeft: 5,
                        position: 'absolute',
                        top: '45%',
                        right: 10,
                      }}
                    ></span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </InfiniteScroll>
      <div
        onClick={handleMarkAllAsRead}
        className={`border fs-8 fw-semibold text-end ${
          notificationCount > 0 ? 'text-info text-hover-primary' : 'text-muted'
        } px-6 py-2 cursor-pointer`}
      >
        {intl.formatMessage({id: 'MARK_ALL_AS_READ'})}
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
