import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {reInitMenu} from '../helpers'
import {ThemeModeProvider} from '../partials'
import {FilterHeaderDataProvider} from '../partials/tdi-mc/filter-header/FilterHeaderData'
import {LayoutContent} from './LayoutContent'
import {PageDataProvider} from './core'
import NotificationComponent from '../partials/layout/notification/NotificationComponent'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <FilterHeaderDataProvider>
        <ThemeModeProvider>
          <LayoutContent />
          <NotificationComponent />
        </ThemeModeProvider>
      </FilterHeaderDataProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
